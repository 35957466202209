/* eslint-disable default-case */
import { types } from "../../types";

const initalState = {
  fetching: false,
  success: false,
  data: false,
};
export default function forgotPasswordReducer(state = initalState, action) {
  switch (action.type) {
    case types.auth.forgotPassword.started:
      return { fetching: true, success: false, data: false };
    case types.auth.forgotPassword.success:
      return { fetching: false, success: true, data: action.payload };
    case types.auth.forgotPassword.failed:
      return { fetching: false, success: false, data: action.payload };
    case types.auth.forgotPassword.reset:
      return state;
  }
  return state;
}
