import { Button, Table } from "antd";
import { useMemo, useState } from "react";
import CreateMetaDataModal from "../../common/components/DomainComponents/UpdateAdminUser/CreateMetaDataModal";
import { data } from "../../data/data";
import UpdateMetaDataModal from "../../common/components/DomainComponents/UpdateAdminUser/UpdateMetaDataModal";

/**
 * Utility function to convert metadata into the dataSource format for the table.
 * @param {Array} metadata - Array of metadata objects.
 * @param {Array} metaDataTypes - Array of metadata type definitions.
 * @returns {Array} Transformed dataSource for the table.
 */
const transformMetadataToDataSource = (metadata, metaDataTypes) => {
  if (!metadata || metadata.length === 0) return [];

  // Group metadata by type
  const groupedByType = metadata.reduce((acc, item) => {
    if (!acc[item.type]) {
      acc[item.type] = [];
    }
    acc[item.type].push(item);
    return acc;
  }, {});

  // Transform grouped data into the required structure
  return Object.entries(groupedByType).map(([type, items]) => {
    const typeDefinition = metaDataTypes.find(
      (metaType) => metaType.id === type,
    );
    const fieldName = typeDefinition ? typeDefinition.fieldName : type;

    // Sort items by the 'name' field in alphanumeric order
    const sortedItems = items.sort((a, b) =>
      a.name.localeCompare(b.name, undefined, { numeric: true }),
    );

    return {
      key: type,
      id: type, // Unique identifier per type
      fieldName,
      fieldValues: sortedItems.map((item) => item.name).join(", "),
      itemIds: sortedItems.map((item) => ({ ...item })),
      fieldValuesLastUpdatedAt: new Date(
        Math.max(
          ...sortedItems.map((item) => new Date(item.updatedAt).getTime()),
        ),
      ).toLocaleString(),
    };
  });
};

export default function Settings({ metadata }) {
  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [updateModalVisible, setUpdateModalVisible] = useState(false);
  const [currentType, setCurrentType] = useState(null);
  const [currentId, setCurrentId] = useState(null);
  const [currentValues, setCurrentValues] = useState([]);

  const dataSource = useMemo(() => {
    return transformMetadataToDataSource(metadata, data.metaDataTypes);
  }, [metadata]);

  const columns = useMemo(() => {
    return [
      {
        title: "Field Name",
        dataIndex: "fieldName",
        key: "fieldName",
      },
      {
        title: "Values",
        dataIndex: "fieldValues",
        key: "fieldValues",
        responsive: ["lg"],
      },
      {
        title: "Last Update",
        dataIndex: "fieldValuesLastUpdatedAt",
        key: "fieldValuesLastUpdatedAt",
        responsive: ["lg"],
      },
      {
        title: "",
        dataIndex: "action",
        key: "action",
        fixed: "right",
        render: (text, record) => {
          return (
            <div>
              <Button
                type="primary"
                onClick={() => {
                  setCurrentType(record.fieldName);
                  setCurrentValues(record.itemIds);
                  setCurrentId(record.id);
                  setUpdateModalVisible(true);
                }}
              >
                View/Update
              </Button>
            </div>
          );
        },
      },
    ];
  }, []);

  const refreshData = () => {
    // Logic to refresh data after creating metadata
    // console.log("Data refreshed!");
  };

  return (
    <div>
      <h2>Settings</h2>
      <Button
        type="primary"
        onClick={() => setCreateModalVisible(true)}
        style={{ margin: "0px 0px 10px 0px" }}
      >
        Create Meta Data
      </Button>
      <Table dataSource={dataSource} columns={columns} />
      <CreateMetaDataModal
        visible={createModalVisible}
        setVisible={setCreateModalVisible}
        onFinish={refreshData}
        metaDataTypes={data.metaDataTypes}
      />{" "}
      <UpdateMetaDataModal
        visible={updateModalVisible}
        setVisible={setUpdateModalVisible}
        currentType={currentType}
        currentId={currentId}
        currentValues={currentValues}
        onAddNewValue={(newValue) => {
          // Add logic to handle new value addition
        }}
        onUpdateValue={(updatedValue) => {
          // Add logic to handle value update
        }}
        onDeleteValue={(id) => {
          // Add logic to handle value deletion
        }}
        metaDataTypes={data.metaDataTypes}
      />
    </div>
  );
}
