import { Button, Form, Input, message } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { ac } from "../../store/actionCreators";

export default function ForgotPassword() {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [username, setUserName] = useState(false);
  const [form] = Form.useForm();
  const history = useHistory();
  const usernameSubmitSuccess = useSelector(function ({ forgotPassword }) {
    return forgotPassword.success;
  });
  const forgotPsswordSubmitSuccess = useSelector(function ({
    forgotPasswordSubmit,
  }) {
    return forgotPasswordSubmit.success;
  });
  const usernameSubmitFetching = useSelector(function ({ forgotPassword }) {
    return forgotPassword.fetching;
  });
  const forgotPasswordSubmitFetching = useSelector(function ({
    forgotPasswordSubmit,
  }) {
    return forgotPasswordSubmit.fetching;
  });
  const usernameSubmitMessage = useSelector(function ({ forgotPassword }) {
    return forgotPassword.data?.message;
  });
  const submitUsername = () => {
    dispatch(ac.forgotPassword(username));
  };
  const onFinish = (data) => {
    let input = {
      username: username,
      code: data.code,
      new_password: data.new_password,
    };
    dispatch(ac.forgotPasswordSubmit(input));
  };

  useEffect(() => {
    if (usernameSubmitSuccess) {
      setVisible(true);
    }
    if (!usernameSubmitSuccess && usernameSubmitMessage) {
      message.warn(usernameSubmitMessage ? usernameSubmitMessage : "Error");
    }
  }, [usernameSubmitMessage, usernameSubmitSuccess]);

  useEffect(() => {
    if (forgotPsswordSubmitSuccess) {
      message.success("Success");
      form.resetFields();
      setVisible(false);
      history.push("/");
    }
  }, [forgotPsswordSubmitSuccess, form, history]);

  return (
    <div>
      <Form form={form} onFinish={onFinish}>
        <h2>Forgot Password</h2>
        {!visible && (
          <div>
            <Form.Item name="username" label="Email" required={true}>
              <Input onChange={(e) => setUserName(e.target.value)} />
            </Form.Item>
            <Button
              type="primary"
              onClick={submitUsername}
              loading={usernameSubmitFetching}
            >
              Next
            </Button>
          </div>
        )}
        {visible && (
          <div>
            <p>We have sent a verfication code to {username}</p>
            <Form.Item name="code" label="Code">
              <Input />
            </Form.Item>
            <Form.Item name="new_password" label="New Password">
              <Input />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                loading={forgotPasswordSubmitFetching}
              >
                Submit
              </Button>
            </Form.Item>
          </div>
        )}
      </Form>
    </div>
  );
}
