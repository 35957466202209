import { API } from "@aws-amplify/api";
import { usersByCompanyAndCreatedAt } from "../../graphql/queries";

export default async function getUsersByCompanyIdApi(id) {
  var companyId = "oslofelgretting.no";
  return await API.graphql({
    query: usersByCompanyAndCreatedAt,
    variables: { companyId, sortDirection: "DESC", limit: 2000 },
  });
}
