/* eslint-disable default-case */
import { types } from "../../types";

const initalState = {
  fetching: false,
  success: false,
  data: false,
};
export default function signInReducer(state = initalState, action) {
  switch (action.type) {
    case types.auth.signIn.started:
      return { fetching: true, success: false, data: false };
    case types.auth.signIn.success:
      return { fetching: false, success: true, data: action.payload };
    case types.auth.signIn.failed:
      return { fetching: false, success: false, data: action.payload };
    case types.auth.signIn.reset:
      return state;
  }
  return state;
}
