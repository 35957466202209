/* eslint-disable default-case */
import { types } from "../../types";

const initialState = {
  fetching: false,
  success: false,
  data: false,
  error: false,
};

export default function getTicketByIdReducer(state = initialState, action) {
  switch (action.type) {
    case types.ticket.getTicketById.started:
      return { fetching: true, success: false, data: false };
    case types.ticket.getTicketById.success:
      return { fetching: false, success: true, data: action.payload.data };
    case types.reset.ticket:
      return { fetching: false, success: false, data: false, error: false };
    case types.ticket.getTicketById.failed:
      return {
        fetching: false,
        success: false,
        data: action.payload,
        error: true,
      };
  }
  return state;
}
