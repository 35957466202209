import { API, graphqlOperation } from "@aws-amplify/api";
import { deleteTicket } from "../../graphql/mutations";

export default async function deleteTicketByIdApi(input) {
  const variables = {
    input,
  };

  return await API.graphql(graphqlOperation(deleteTicket, variables));
}
