import React from "react";
import { Redirect, Route } from "react-router";

export default function ProtectedRoute({ children, signedIn }) {
  return (
    <Route
      render={function () {
        return signedIn ? React.cloneElement(children) : <Redirect to="/" />;
      }}
    />
  );
}
