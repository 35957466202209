import api from "../../../api";
import { types } from "../../types";

export default function deleteTicketAction(values) {
  return async function deleteTciketActionThunk(dispatch) {
    dispatch({ type: types.ticket.delete.started, payload: false });
    try {
      const data = await api.ticket.delete(values);
      dispatch({ type: types.ticket.delete.success, payload: data });
    } catch (error) {
      dispatch({ type: types.ticket.delete.failed, payload: error });
    }
    dispatch({ type: types.ticket.delete.reset });
  };
}
