import api from "../../../api";
import { types } from "../../types";

export default function getUserByEmailAction(values) {
  return async function getUserByEmailThunk(dispatch) {
    dispatch({ type: types.user.byEmail.started, payload: false });

    try {
      const data = await api.user.byEmail(values);
      dispatch({ type: types.user.byEmail.success, payload: data });
    } catch (error) {
      dispatch({ type: types.user.byEmail.failed, payload: error });
    }
    // dispatch({ type: types.user.byEmail.reset });
  };
}
