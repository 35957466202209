import api from "../../../api";
import { types } from "../../types";

export default function createUserAction(values) {
  return async function createUserThunk(dispatch) {
    dispatch({ type: types.user.create.started, payload: false });

    try {
      const data = await api.user.create(values);
      dispatch({ type: types.user.create.success, payload: data });
    } catch (error) {
      dispatch({ type: types.user.create.failed, payload: error });
    }
    dispatch({ type: types.user.create.reset });
  };
}
