import api from "../../../api";
import { types } from "../../types";

export default function jobsBatchGetAction(ids) {
  return async function jobsBatchGetActionThunk(dispatch) {
    dispatch({ type: types.job.batchGet.started });

    try {
      const data = await api.job.batchGet(ids);
      dispatch({ type: types.job.batchGet.success, payload: data });
    } catch (error) {
      dispatch({ type: types.job.batchGet.failed, payload: error });
    }
  };
}
