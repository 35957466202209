import React, { useState, useEffect, useCallback } from "react";
import { Modal, Form, Input, Button, Table, message } from "antd";
import classNames from "./updateAdminUserModal.module.scss";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "../../../../api";

export default function UpdateMetaDataModal({
  visible,
  setVisible,
  currentType,
  currentValues, // [{ id: "1", name: "Welding", additionalInfo: { field1: "value1" } }, ...]
  metaDataTypes, // To fetch subfields for the type
  onAddNewValue,
  onUpdateValue, // Remove
  onDeleteValue, // Remmov
  currentId,
}) {
  const [form] = Form.useForm();
  const [editingRow, setEditingRow] = useState(null);
  const [editingValue, setEditingValue] = useState({});
  const [subFields, setSubFields] = useState([]);
  const queryClient = useQueryClient();

  // Fetch subfields for the selected type
  useEffect(() => {
    const metaType = metaDataTypes.find((type) => type.id === currentId);
    setSubFields(metaType?.subFields || []);
  }, [currentId, metaDataTypes]);

  const addNewValueFn = (values) => api.metaData.create(values);
  const addNewValueMn = useMutation(addNewValueFn);
  const addValueOnSuccess = useCallback(() => {
    queryClient.invalidateQueries("getmetadataByCompanyId");
    form.resetFields();
    message.success("Meta data created successfully.");
  }, [form, queryClient]);

  const handleAddNewValue = (values) => {
    const additionalInfo = {};
    subFields.forEach((field) => {
      if (values[field.id]) {
        additionalInfo[field.id] = values[field.id];
      }
    });

    // Trigger API mutation
    addNewValueMn.mutate(
      {
        input: {
          type: currentId,
          name: values.name,
          additionalInfo: JSON.stringify(additionalInfo),
        },
      },
      {
        onSuccess: addValueOnSuccess,
        onError: () => {
          message.error("Error creating meta data!");
        },
      },
    );
  };

  const handleUpdateClick = (record) => {
    setEditingRow(record.id);

    // Parse `additionalInfo` if it's a JSON string
    const parsedAdditionalInfo = record.additionalInfo
      ? JSON.parse(record.additionalInfo)
      : {};

    // Set the initial editing value including subfields
    setEditingValue({ name: record.name, ...parsedAdditionalInfo });
  };

  const updateMetaDataFn = (values) => api.metaData.update(values);
  const updateMetaDataMn = useMutation(updateMetaDataFn);
  const handleSaveClick = (record) => {
    const input = {
      id: record.id,
      name: editingValue.name,
      additionalInfo: JSON.stringify(
        subFields.reduce((acc, field) => {
          acc[field.id] = editingValue[field.id];
          return acc;
        }, {}),
      ),
    };
    updateMetaDataMn.mutate(input, {
      onSuccess: () => {
        queryClient.invalidateQueries("getmetadataByCompanyId"); // Refetch data
        message.success("Meta data updated successfully.");
        setEditingRow(null);
        setEditingValue({});
      },
      onError: () => {
        message.error("Error updating meta data!");
      },
    });
  };

  const handleCancelClick = () => {
    setEditingRow(null);
    setEditingValue({});
  };

  const deleteMetaDataFn = (id) => api.metaData.delete(id);
  const deleteMetaDataMn = useMutation(deleteMetaDataFn);
  const handleDeleteClick = (id) => {
    const input = { id };
    deleteMetaDataMn.mutate(input, {
      onSuccess: () => {
        queryClient.invalidateQueries("getmetadataByCompanyId"); // Refetch data
        message.success("Meta data deleted successfully.");
      },
      onError: () => {
        message.error("Error deleting meta data!");
      },
    });
  };

  const columns = [
    {
      title: "Value",
      dataIndex: "name",
      key: "name",
      render: (text, record) =>
        editingRow === record.id ? (
          <Input
            value={editingValue.name}
            onChange={(e) =>
              setEditingValue((prev) => ({ ...prev, name: e.target.value }))
            }
          />
        ) : (
          text
        ),
    },
    ...subFields.map((field) => ({
      title: field.fieldName,
      dataIndex: field.id,
      key: field.id,
      render: (text, record) => {
        return editingRow === record.id ? (
          <Input
            value={editingValue[field.id] || ""}
            onChange={(e) =>
              setEditingValue((prev) => ({
                ...prev,
                [field.id]: e.target.value,
              }))
            }
          />
        ) : (
          JSON.parse(record.additionalInfo)?.[field.id] || "-"
        );
      },
    })),
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <>
          {editingRow === record.id ? (
            <>
              <Button type="primary" onClick={() => handleSaveClick(record)}>
                Save
              </Button>
              <Button type="secondary" onClick={handleCancelClick}>
                Cancel
              </Button>
            </>
          ) : (
            <>
              <Button type="primary" onClick={() => handleUpdateClick(record)}>
                Update
              </Button>
              <Button
                type="secondary"
                onClick={() => handleDeleteClick(record.id)}
              >
                Delete
              </Button>
            </>
          )}
        </>
      ),
    },
  ];

  return (
    <Modal
      open={visible}
      onCancel={() => setVisible(false)}
      footer={null}
      title={`Update Field Data: ${currentType}`}
    >
      <div className={classNames.wrapper}>
        <h3>Add New Value</h3>
      </div>
      <Form
        form={form}
        onFinish={(values) => {
          handleAddNewValue(values);
        }}
      >
        <Form.Item
          name="name"
          label="Value Name"
          rules={[{ required: true, message: "Please input a value name!" }]}
        >
          <Input placeholder="Enter value name" />
        </Form.Item>
        {subFields.map((field) => (
          <Form.Item
            key={field.id}
            name={field.id}
            label={field.fieldName}
            rules={[
              { required: true, message: `${field.fieldName} is required.` },
              {
                validator: (_, value) => {
                  if (field.id === "servicesWithDueDates" && isNaN(value)) {
                    return Promise.reject(new Error("Must be a number."));
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input placeholder={`Enter ${field.fieldName}`} />
          </Form.Item>
        ))}
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Add New Value
          </Button>
        </Form.Item>
      </Form>

      <h3>Values</h3>
      <Table
        columns={columns}
        dataSource={currentValues}
        rowKey={(record) => record.id}
        pagination={false}
      />
    </Modal>
  );
}
