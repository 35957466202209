import classNames from "./clientView.module.scss";
// import CreateNewTicket from "./CreateNewTicket/CreateNewTicket";
import image from "../Assests/Images/bgPic.png";
import CookieConsent from "react-cookie-consent";
export default function ClientView() {
  return (
    <div className={classNames.wrapper}>
      <div className={classNames.image}>
        <img src={image} alt="Oslo felgretting" />
      </div>
      {/* <div className={classNames.createNewTicket}>
        <CreateNewTicket />
      </div> */}
      <CookieConsent
        location="bottom"
        buttonText="Accept"
        cookieName="cookieOne"
        style={{ background: "purple" }}
        buttonStyle={{ color: "purple", fontSize: "13px", background: "white" }}
        expires={150}
      >
        This website uses cookies to enhance the user experience.{" "}
        {/* <span style={{ fontSize: "10px" }}>This bit of text is smaller :O</span> */}
      </CookieConsent>
    </div>
  );
}
