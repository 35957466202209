import AWS, { Auth } from "aws-amplify";
import { useDispatch, useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import "./App.less";
import awsmobile from "./aws-exports";
import ClientView from "./ClientView/ClientView";
import Header from "./Header/Header";
import Dashboard from "./Admin/Dashboard/Dashboard";
import ProtectedRoute from "./common/ProtectedRoute/ProtectedRoute";
import { useEffect, useState } from "react";
import { ac } from "./store/actionCreators";
import ForgotPassword from "./Admin/Auth/ForgotPassword";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
function App() {
  const queryClient = new QueryClient();
  AWS.configure(awsmobile);

  const [sessionObj, setSessionObj] = useState(null);
  // const state = useSelector((state) => state);
  const dispatch = useDispatch();

  const signedInSuccess = useSelector(({ signIn }) => signIn.success);
  const signedIn = sessionObj ? sessionObj : signedInSuccess;
  // const userObject = useSelector(({ signIn }) => signIn.data);

  const fetchUser = async () => {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      const currentSession = await Auth.currentSession();
      cognitoUser.refreshSession(
        currentSession.refreshToken,
        (err, session) => {
          setSessionObj(session);
          // const { idToken, refreshToken, accessToken } = session;
          // do whatever you want to do now :)
        },
      );
    } catch (e) {
      console.log("Unable to refresh Token", e);
    }
  };
  // const user = async() => (await Auth.currentSession()).getRefreshToken;

  // const currentInfo = Auth.currentSession();
  // console.log("currentInfo: ", currentInfo);
  // useEffect(() => {
  //   if(signedIn){
  //     sessionStorage.setItem("userObject", JSON.stringify(userObject))
  //   }
  // }, [signedIn, userObject])

  useEffect(() => {
    fetchUser();
    dispatch(ac.fetchCurrentUser());
    dispatch(ac.listAdminUsers());
  }, [dispatch]);
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <Header signedIn={signedIn} />
        <div>
          <Switch>
            {/* <Route path="/admin">
            <SignIn />{" "}
          </Route> */}
            <Route path="/forgot-password">
              <ForgotPassword />{" "}
            </Route>
            <Route path="/create-ticket">
              <ClientView />
            </Route>
            <ProtectedRoute path="/dashboard" signedIn={signedIn}>
              <Dashboard />
            </ProtectedRoute>
            <Route path="/*">
              <Redirect to="/create-ticket" />
            </Route>
          </Switch>
        </div>
      </Router>
    </QueryClientProvider>
  );
}

export default App;
