/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_appsync_graphqlEndpoint": "https://6h65ptllk5bofmbrd7rxcxfjkq.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-eklpz6q6m5a7xf3rfeflx5c5te",
    "aws_cognito_identity_pool_id": "us-west-2:40b0126b-8b05-4109-8fda-29b2ba9cdf24",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_3o5ZndwlV",
    "aws_user_pools_web_client_id": "1an4jmpjgmh12ann8e2uf04b3t",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "ticketbookingapp80243da216cc465cb9921d2f9639e57231754-prod",
    "aws_user_files_s3_bucket_region": "us-west-2"
};


export default awsmobile;
