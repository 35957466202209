import { API, graphqlOperation } from "aws-amplify";
import { createNewTicket } from "../../graphql/mutations";
import { data } from "../../data/data";

export default async function createTicketApi(
  input,
  userId,
  customerName,
  customerEmail,
  customerMobileNumber,
) {
  let companyId = "oslofelgretting.no";
  let jobsArrray = JSON.stringify(input.services);
  let ticketData = {
    userId: userId,
    mobileNumber: customerMobileNumber,
    customerName: customerName,
    customerEmail: customerEmail,
    companyId: companyId,
    jobs: jobsArrray,
    notes: input && input.notes ? input.notes : "",
    ticketStatus: data.status[0],
    dateOfReceipt: new Date().toISOString(),
    //dateOfReceipt variable is equals to dateIn variable on dashboard as per the client request.
    //Ticket link: https://mysn.atlassian.net/browse/TS-106
    expectedDelivery: input.expectedDelivery ? input.expectedDelivery : null,
    //expectedDelivery variable is equals to expectedDeliveryOut variable on dashboard as per the client request.
    //Ticket link: https://mysn.atlassian.net/browse/TS-107
    actualDelivery: input.expectedDelivery ? input.expectedDelivery : null,
    //actualDelivery variable is equals to dateOut variable on dashboard as per the client request.
    //Ticket link: https://mysn.atlassian.net/browse/TS-108
  };

  return await API.graphql(
    graphqlOperation(createNewTicket, { ...ticketData }),
  );
}
