/* eslint-disable default-case */
import { types } from "../../types";

const initialState = {
  fetching: false,
  success: false,
  data: false,
  error: false,
};

export default function getUsersByCompanyIdReducer(
  state = initialState,
  action,
) {
  switch (action.type) {
    case types.user.getUsersByComapnyId.started:
      return { fetching: true, success: false, data: false };
    case types.user.getUsersByComapnyId.success:
      return { fetching: false, success: true, data: action.payload.data };
    case types.user.getUsersByComapnyId.failed:
      return {
        fetching: false,
        success: false,
        data: action.payload,
        error: true,
      };
    case types.user.getUsersByComapnyId.reset:
      return { fetching: false, success: false, data: false, error: false };
  }
  return state;
}
