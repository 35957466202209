import api from "../../../api";
import { types } from "../../types";

export default function updateJobAction(values) {
  return async function updateJobActionThunk(dispatch) {
    dispatch({ type: types.job.update.started, payload: false });

    try {
      const data = await api.job.update(values);
      dispatch({ type: types.job.update.success, payload: data });
    } catch (error) {
      dispatch({ type: types.job.update.failed, payload: error });
    }
  };
}
