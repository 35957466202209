import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export function useFetchUser() {
  const [userObj, setUserObj] = useState();
  const adminUserList = useSelector(function ({ listAdminUsers }) {
    return listAdminUsers.data
      ? listAdminUsers.data
        ? listAdminUsers.data.listAdminUsers
        : []
      : [];
  });

  const fetchCurrentUserData = useSelector(function ({ fetchCurrentUser }) {
    return fetchCurrentUser ? fetchCurrentUser.data : {};
  });

  const signInUserData = useSelector(function ({ signIn }) {
    return signIn ? signIn.data : {};
  });

  useEffect(() => {
    let userObj = signInUserData || fetchCurrentUserData;
    setUserObj(userObj);
  }, [fetchCurrentUserData, signInUserData]);

  const currentUserObj =
    userObj &&
    adminUserList.items &&
    adminUserList.items.length > 0 &&
    adminUserList.items.find((value, index) => value.id === userObj.username);

  return currentUserObj;
}
