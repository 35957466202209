import api from "../../../api";
import { types } from "../../types";

export default function forgotPasswordAction(values) {
  return async function forgotPasswordActionThunk(dispatch) {
    dispatch({ type: types.auth.forgotPassword.started, payload: false });
    try {
      const data = await api.auth.forgotPassword(values);
      dispatch({ type: types.auth.forgotPassword.success, payload: data });
    } catch (error) {
      dispatch({ type: types.auth.forgotPassword.failed, payload: error });
    }
  };
}
