/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const jobsBatchGet = /* GraphQL */ `
  query JobsBatchGet($jobIds: [String]!) {
    jobsBatchGet(jobIds: $jobIds) {
      id
      jobDisplayId
      userId
      companyId
      jobName
      numberOfRims
      brandOfRims
      sizeOfRims
      color
      matt
      brandOfTyre
      noOfCaps
      noOfTPMS
      sizeOfTyre
      makeOfVehicle
      vehicleNumberPlate
      notes
      imagesUrl
      dateOfReceipt
      expectedDelivery
      actualDelivery
      jobStatus
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTicketsByCompanyId = /* GraphQL */ `
  query ListTicketsByCompanyId(
    $companyId: String!
    $userId: String
    $mobileNumber: String
    $customerName: String
    $name: String
    $ticketDisplayId: String
    $LastEvaluatedKey: String
  ) {
    listTicketsByCompanyId(
      companyId: $companyId
      userId: $userId
      mobileNumber: $mobileNumber
      customerName: $customerName
      name: $name
      ticketDisplayId: $ticketDisplayId
      LastEvaluatedKey: $LastEvaluatedKey
    ) {
      id
      ticketDisplayId
      userId
      mobileNumber
      customerEmail
      customerName
      companyId
      notes
      dateOfReceipt
      expectedDelivery
      actualDelivery
      ticketStatus
      user {
        id
        name
        email
        mobileNumber
        companyId
        type
        keywords
        createdAt
        updatedAt
        __typename
      }
      company {
        id
        name
        website
        ownerId
        owner {
          id
          name
          email
          mobileNumber
          companyId
          type
          keywords
          createdAt
          updatedAt
          __typename
        }
        tickets {
          nextToken
          __typename
        }
        services {
          nextToken
          __typename
        }
        jobs {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      jobs
      jobsArray {
        items {
          id
          jobDisplayId
          userId
          companyId
          ticketId
          jobName
          numberOfRims
          brandOfRims
          sizeOfRims
          color
          matt
          brandOfTyre
          sizeOfTyre
          noOfCaps
          noOfTPMS
          makeOfVehicle
          vehicleNumberPlate
          notes
          imagesUrl
          dateOfReceipt
          expectedDelivery
          actualDelivery
          jobStatus
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      jobNames
      keywords
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const getUserByEmail = /* GraphQL */ `
  query GetUserByEmail(
    $companyId: String!
    $email: String
    $mobileNumber: String
  ) {
    getUserByEmail(
      companyId: $companyId
      email: $email
      mobileNumber: $mobileNumber
    ) {
      id
      name
      email
      mobileNumber
      companyId
      type
      keywords
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const getCompany = /* GraphQL */ `
  query GetCompany($id: ID!) {
    getCompany(id: $id) {
      id
      name
      website
      ownerId
      owner {
        id
        name
        email
        mobileNumber
        companyId
        type
        keywords
        createdAt
        updatedAt
        __typename
      }
      tickets {
        items {
          id
          ticketDisplayId
          userId
          mobileNumber
          customerEmail
          customerName
          companyId
          notes
          dateOfReceipt
          expectedDelivery
          actualDelivery
          ticketStatus
          jobs
          jobNames
          keywords
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      services {
        items {
          id
          companyId
          name
          createdBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      jobs {
        items {
          id
          jobDisplayId
          userId
          companyId
          ticketId
          jobName
          numberOfRims
          brandOfRims
          sizeOfRims
          color
          matt
          brandOfTyre
          sizeOfTyre
          noOfCaps
          noOfTPMS
          makeOfVehicle
          vehicleNumberPlate
          notes
          imagesUrl
          dateOfReceipt
          expectedDelivery
          actualDelivery
          jobStatus
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCompanys = /* GraphQL */ `
  query ListCompanys(
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        website
        ownerId
        owner {
          id
          name
          email
          mobileNumber
          companyId
          type
          keywords
          createdAt
          updatedAt
          __typename
        }
        tickets {
          nextToken
          __typename
        }
        services {
          nextToken
          __typename
        }
        jobs {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      name
      email
      mobileNumber
      companyId
      type
      keywords
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        email
        mobileNumber
        companyId
        type
        keywords
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAdminUser = /* GraphQL */ `
  query GetAdminUser($id: ID!) {
    getAdminUser(id: $id) {
      id
      name
      email
      dpUrl
      mobileNumber
      companyId
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAdminUsers = /* GraphQL */ `
  query ListAdminUsers(
    $filter: ModelAdminUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdminUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        email
        dpUrl
        mobileNumber
        companyId
        type
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTicket = /* GraphQL */ `
  query GetTicket($id: ID!) {
    getTicket(id: $id) {
      id
      ticketDisplayId
      userId
      mobileNumber
      customerEmail
      customerName
      companyId
      notes
      dateOfReceipt
      expectedDelivery
      actualDelivery
      ticketStatus
      user {
        id
        name
        email
        mobileNumber
        companyId
        type
        keywords
        createdAt
        updatedAt
        __typename
      }
      company {
        id
        name
        website
        ownerId
        owner {
          id
          name
          email
          mobileNumber
          companyId
          type
          keywords
          createdAt
          updatedAt
          __typename
        }
        tickets {
          nextToken
          __typename
        }
        services {
          nextToken
          __typename
        }
        jobs {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      jobs
      jobsArray {
        items {
          id
          jobDisplayId
          userId
          companyId
          ticketId
          jobName
          numberOfRims
          brandOfRims
          sizeOfRims
          color
          matt
          brandOfTyre
          sizeOfTyre
          noOfCaps
          noOfTPMS
          makeOfVehicle
          vehicleNumberPlate
          notes
          imagesUrl
          dateOfReceipt
          expectedDelivery
          actualDelivery
          jobStatus
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      jobNames
      keywords
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTickets = /* GraphQL */ `
  query ListTickets(
    $filter: ModelTicketFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTickets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ticketDisplayId
        userId
        mobileNumber
        customerEmail
        customerName
        companyId
        notes
        dateOfReceipt
        expectedDelivery
        actualDelivery
        ticketStatus
        user {
          id
          name
          email
          mobileNumber
          companyId
          type
          keywords
          createdAt
          updatedAt
          __typename
        }
        company {
          id
          name
          website
          ownerId
          createdAt
          updatedAt
          __typename
        }
        jobs
        jobsArray {
          nextToken
          __typename
        }
        jobNames
        keywords
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getService = /* GraphQL */ `
  query GetService($id: ID!) {
    getService(id: $id) {
      id
      companyId
      name
      createdBy
      company {
        id
        name
        website
        ownerId
        owner {
          id
          name
          email
          mobileNumber
          companyId
          type
          keywords
          createdAt
          updatedAt
          __typename
        }
        tickets {
          nextToken
          __typename
        }
        services {
          nextToken
          __typename
        }
        jobs {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listServices = /* GraphQL */ `
  query ListServices(
    $filter: ModelServiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listServices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        companyId
        name
        createdBy
        company {
          id
          name
          website
          ownerId
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getJob = /* GraphQL */ `
  query GetJob($id: ID!) {
    getJob(id: $id) {
      id
      jobDisplayId
      userId
      companyId
      ticketId
      jobName
      numberOfRims
      brandOfRims
      sizeOfRims
      color
      matt
      brandOfTyre
      sizeOfTyre
      noOfCaps
      noOfTPMS
      makeOfVehicle
      vehicleNumberPlate
      notes
      imagesUrl
      dateOfReceipt
      expectedDelivery
      actualDelivery
      jobStatus
      user {
        id
        name
        email
        mobileNumber
        companyId
        type
        keywords
        createdAt
        updatedAt
        __typename
      }
      company {
        id
        name
        website
        ownerId
        owner {
          id
          name
          email
          mobileNumber
          companyId
          type
          keywords
          createdAt
          updatedAt
          __typename
        }
        tickets {
          nextToken
          __typename
        }
        services {
          nextToken
          __typename
        }
        jobs {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      ticket {
        id
        ticketDisplayId
        userId
        mobileNumber
        customerEmail
        customerName
        companyId
        notes
        dateOfReceipt
        expectedDelivery
        actualDelivery
        ticketStatus
        user {
          id
          name
          email
          mobileNumber
          companyId
          type
          keywords
          createdAt
          updatedAt
          __typename
        }
        company {
          id
          name
          website
          ownerId
          createdAt
          updatedAt
          __typename
        }
        jobs
        jobsArray {
          nextToken
          __typename
        }
        jobNames
        keywords
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listJobs = /* GraphQL */ `
  query ListJobs(
    $filter: ModelJobFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listJobs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        jobDisplayId
        userId
        companyId
        ticketId
        jobName
        numberOfRims
        brandOfRims
        sizeOfRims
        color
        matt
        brandOfTyre
        sizeOfTyre
        noOfCaps
        noOfTPMS
        makeOfVehicle
        vehicleNumberPlate
        notes
        imagesUrl
        dateOfReceipt
        expectedDelivery
        actualDelivery
        jobStatus
        user {
          id
          name
          email
          mobileNumber
          companyId
          type
          keywords
          createdAt
          updatedAt
          __typename
        }
        company {
          id
          name
          website
          ownerId
          createdAt
          updatedAt
          __typename
        }
        ticket {
          id
          ticketDisplayId
          userId
          mobileNumber
          customerEmail
          customerName
          companyId
          notes
          dateOfReceipt
          expectedDelivery
          actualDelivery
          ticketStatus
          jobs
          jobNames
          keywords
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMetaData = /* GraphQL */ `
  query GetMetaData($id: ID!) {
    getMetaData(id: $id) {
      id
      type
      name
      additionalInfo
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listMetaDatas = /* GraphQL */ `
  query ListMetaDatas(
    $filter: ModelMetaDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMetaDatas(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        name
        additionalInfo
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserByEmailId = /* GraphQL */ `
  query GetUserByEmailId(
    $email: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUserByEmailId(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        email
        mobileNumber
        companyId
        type
        keywords
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUsersByCompanyId = /* GraphQL */ `
  query GetUsersByCompanyId(
    $companyId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUsersByCompanyId(
      companyId: $companyId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        email
        mobileNumber
        companyId
        type
        keywords
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const usersByCompanyAndCreatedAt = /* GraphQL */ `
  query UsersByCompanyAndCreatedAt(
    $companyId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByCompanyAndCreatedAt(
      companyId: $companyId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        email
        mobileNumber
        companyId
        type
        keywords
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAdminUsersByCompanyId = /* GraphQL */ `
  query GetAdminUsersByCompanyId(
    $companyId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelAdminUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getAdminUsersByCompanyId(
      companyId: $companyId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        email
        dpUrl
        mobileNumber
        companyId
        type
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTicketsByCompanyId = /* GraphQL */ `
  query GetTicketsByCompanyId(
    $companyId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelTicketFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTicketsByCompanyId(
      companyId: $companyId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ticketDisplayId
        userId
        mobileNumber
        customerEmail
        customerName
        companyId
        notes
        dateOfReceipt
        expectedDelivery
        actualDelivery
        ticketStatus
        user {
          id
          name
          email
          mobileNumber
          companyId
          type
          keywords
          createdAt
          updatedAt
          __typename
        }
        company {
          id
          name
          website
          ownerId
          createdAt
          updatedAt
          __typename
        }
        jobs
        jobsArray {
          nextToken
          __typename
        }
        jobNames
        keywords
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listTicketsForDashboardFilterByTicketDisplayId = /* GraphQL */ `
  query ListTicketsForDashboardFilterByTicketDisplayId(
    $ticketDisplayId: String
    $sortDirection: ModelSortDirection
    $filter: ModelTicketFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTicketsForDashboardFilterByTicketDisplayId(
      ticketDisplayId: $ticketDisplayId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ticketDisplayId
        userId
        mobileNumber
        customerEmail
        customerName
        companyId
        notes
        dateOfReceipt
        expectedDelivery
        actualDelivery
        ticketStatus
        user {
          id
          name
          email
          mobileNumber
          companyId
          type
          keywords
          createdAt
          updatedAt
          __typename
        }
        company {
          id
          name
          website
          ownerId
          createdAt
          updatedAt
          __typename
        }
        jobs
        jobsArray {
          nextToken
          __typename
        }
        jobNames
        keywords
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listTicketsForDashboardFilterByUserId = /* GraphQL */ `
  query ListTicketsForDashboardFilterByUserId(
    $userId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelTicketFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTicketsForDashboardFilterByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ticketDisplayId
        userId
        mobileNumber
        customerEmail
        customerName
        companyId
        notes
        dateOfReceipt
        expectedDelivery
        actualDelivery
        ticketStatus
        user {
          id
          name
          email
          mobileNumber
          companyId
          type
          keywords
          createdAt
          updatedAt
          __typename
        }
        company {
          id
          name
          website
          ownerId
          createdAt
          updatedAt
          __typename
        }
        jobs
        jobsArray {
          nextToken
          __typename
        }
        jobNames
        keywords
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listTicketsForDashboardFilterBycustomerName = /* GraphQL */ `
  query ListTicketsForDashboardFilterBycustomerName(
    $customerName: String
    $sortDirection: ModelSortDirection
    $filter: ModelTicketFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTicketsForDashboardFilterBycustomerName(
      customerName: $customerName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ticketDisplayId
        userId
        mobileNumber
        customerEmail
        customerName
        companyId
        notes
        dateOfReceipt
        expectedDelivery
        actualDelivery
        ticketStatus
        user {
          id
          name
          email
          mobileNumber
          companyId
          type
          keywords
          createdAt
          updatedAt
          __typename
        }
        company {
          id
          name
          website
          ownerId
          createdAt
          updatedAt
          __typename
        }
        jobs
        jobsArray {
          nextToken
          __typename
        }
        jobNames
        keywords
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listTicketsForDashboardFilterByMobileNumber = /* GraphQL */ `
  query ListTicketsForDashboardFilterByMobileNumber(
    $mobileNumber: String
    $sortDirection: ModelSortDirection
    $filter: ModelTicketFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTicketsForDashboardFilterByMobileNumber(
      mobileNumber: $mobileNumber
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ticketDisplayId
        userId
        mobileNumber
        customerEmail
        customerName
        companyId
        notes
        dateOfReceipt
        expectedDelivery
        actualDelivery
        ticketStatus
        user {
          id
          name
          email
          mobileNumber
          companyId
          type
          keywords
          createdAt
          updatedAt
          __typename
        }
        company {
          id
          name
          website
          ownerId
          createdAt
          updatedAt
          __typename
        }
        jobs
        jobsArray {
          nextToken
          __typename
        }
        jobNames
        keywords
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listTicketsByCompanyAndCreatedAt = /* GraphQL */ `
  query ListTicketsByCompanyAndCreatedAt(
    $companyId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTicketFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTicketsByCompanyAndCreatedAt(
      companyId: $companyId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ticketDisplayId
        userId
        mobileNumber
        customerEmail
        customerName
        companyId
        notes
        dateOfReceipt
        expectedDelivery
        actualDelivery
        ticketStatus
        user {
          id
          name
          email
          mobileNumber
          companyId
          type
          keywords
          createdAt
          updatedAt
          __typename
        }
        company {
          id
          name
          website
          ownerId
          createdAt
          updatedAt
          __typename
        }
        jobs
        jobsArray {
          items {
            id
            jobDisplayId
            jobName
            numberOfRims
            sizeOfRims
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        jobNames
        keywords
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getServicesByCompanyId = /* GraphQL */ `
  query GetServicesByCompanyId(
    $companyId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelServiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getServicesByCompanyId(
      companyId: $companyId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        companyId
        name
        createdBy
        company {
          id
          name
          website
          ownerId
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getJobsByCompanyId = /* GraphQL */ `
  query GetJobsByCompanyId(
    $companyId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelJobFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getJobsByCompanyId(
      companyId: $companyId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        jobDisplayId
        userId
        companyId
        ticketId
        jobName
        numberOfRims
        brandOfRims
        sizeOfRims
        color
        matt
        brandOfTyre
        sizeOfTyre
        noOfCaps
        noOfTPMS
        makeOfVehicle
        vehicleNumberPlate
        notes
        imagesUrl
        dateOfReceipt
        expectedDelivery
        actualDelivery
        jobStatus
        user {
          id
          name
          email
          mobileNumber
          companyId
          type
          keywords
          createdAt
          updatedAt
          __typename
        }
        company {
          id
          name
          website
          ownerId
          createdAt
          updatedAt
          __typename
        }
        ticket {
          id
          ticketDisplayId
          userId
          mobileNumber
          customerEmail
          customerName
          companyId
          notes
          dateOfReceipt
          expectedDelivery
          actualDelivery
          ticketStatus
          jobs
          jobNames
          keywords
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getJobsByTicketId = /* GraphQL */ `
  query GetJobsByTicketId(
    $ticketId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelJobFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getJobsByTicketId(
      ticketId: $ticketId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        jobDisplayId
        userId
        companyId
        ticketId
        jobName
        numberOfRims
        brandOfRims
        sizeOfRims
        color
        matt
        brandOfTyre
        sizeOfTyre
        noOfCaps
        noOfTPMS
        makeOfVehicle
        vehicleNumberPlate
        notes
        imagesUrl
        dateOfReceipt
        expectedDelivery
        actualDelivery
        jobStatus
        user {
          id
          name
          email
          mobileNumber
          companyId
          type
          keywords
          createdAt
          updatedAt
          __typename
        }
        company {
          id
          name
          website
          ownerId
          createdAt
          updatedAt
          __typename
        }
        ticket {
          id
          ticketDisplayId
          userId
          mobileNumber
          customerEmail
          customerName
          companyId
          notes
          dateOfReceipt
          expectedDelivery
          actualDelivery
          ticketStatus
          jobs
          jobNames
          keywords
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMetaDataByType = /* GraphQL */ `
  query GetMetaDataByType(
    $type: String
    $sortDirection: ModelSortDirection
    $filter: ModelMetaDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getMetaDataByType(
      type: $type
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        name
        additionalInfo
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMetaDataByTypeAndName = /* GraphQL */ `
  query GetMetaDataByTypeAndName(
    $type: String
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMetaDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getMetaDataByTypeAndName(
      type: $type
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        name
        additionalInfo
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
