import { Result } from "antd";

export default function Notes() {
  return (
    <Result
      title="Notes feature is not available yet"
      subTitle="Contact Nilanka for more info"
      status="warning"
    />
  );
}
