import { Button, Form, Input, Modal } from "antd";
import classNames from "./updateCustomerModal.module.scss";
import { useEffect } from "react";

export default function UpdateCustomerModal({
  onFinish,
  visible,
  record,
  setVisible,
  setRecord,
  dataSource,
}) {
  const [form] = Form.useForm();

  useEffect(() => {
    if (record) {
      form.setFieldsValue({
        name: record.customerName,
        email: record.customerEmail,
        mobileNumber: record.customerMobileNumber,
      });
    }
  }, [record, form])

  return (
    record && (
      <Modal
        open={visible}
        onCancel={() => {
          setVisible(false);
          setRecord(null);
        }}
        footer={null}
      >
        <div className={classNames.wrapper}>
          <h2>Update Customer</h2>
        </div>
        <Form form={form} onFinish={onFinish}>
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
                message: "Name is required.",
              },
              {
                validator: (rule, value, callback) => {
                  var obj =
                    Array.isArray(dataSource) && dataSource.length > 0
                      ? dataSource.find((val) => val.customerName === value)
                      : null;
                  if (obj && !(record.customerName === value)) {
                    callback(`${value} exists`);
                  }
                  callback();
                },
              },
            ]}
          >
            <Input defaultValue={record.customerName} />
          </Form.Item>
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: "Email is required.",
              },
              {
                validator: (rule, value, callback) => {
                  var obj =
                    Array.isArray(dataSource) && dataSource.length > 0
                      ? dataSource.find((val) => val.customerEmail === value)
                      : null;
                  if (obj && !(record.customerEmail === value)) {
                    callback(`${value} exists`);
                  }
                  callback();
                },
              },
            ]}
          >
            <Input defaultValue={record.customerEmail} />
          </Form.Item>
          <Form.Item
            name="mobileNumber"
            rules={[
              {
                required: true,
                message: "Mobile number is required.",
              },
              {
                validator: (rule, value, callback) => {
                  var obj =
                    Array.isArray(dataSource) && dataSource.length > 0
                      ? dataSource.find(
                          (val) => val.customerMobileNumber === value,
                        )
                      : null;
                  if (obj && !(record.customerMobileNumber === value)) {
                    callback(`${value} exists`);
                  }
                  if (value === Number.isNaN) {
                    callback("Should be a number");
                  }
                  if (!Number(value)) {
                    callback("Only numbers allowed");
                  }
                  if (value.length > 8) {
                    callback("Max 8 digits");
                  }
                  if (value.length < 8) {
                    callback("8 digits required");
                  }
                  callback();
                },
              },
            ]}
          >
            <Input defaultValue={record.customerMobileNumber} />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Update
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    )
  );
}
