import { API, graphqlOperation } from "aws-amplify";
import { emailSender } from "../../graphql/mutations";

export default async function sendEmailApi(
  customerEmail,
  customerName,
  ticketStatus,
) {
  let ticketData = {
    toEmail: customerEmail,
    customerName: customerName,
    appointmentStatus: ticketStatus,
  };

  return await API.graphql(graphqlOperation(emailSender, { ...ticketData }));
}
