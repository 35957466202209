/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createNewTicket = /* GraphQL */ `
  mutation CreateNewTicket(
    $userId: ID!
    $companyId: ID!
    $mobileNumber: String
    $customerName: String
    $customerEmail: String
    $notes: String
    $dateOfReceipt: String
    $expectedDelivery: String
    $actualDelivery: String
    $ticketStatus: String!
    $jobs: [String]!
  ) {
    createNewTicket(
      userId: $userId
      companyId: $companyId
      mobileNumber: $mobileNumber
      customerName: $customerName
      customerEmail: $customerEmail
      notes: $notes
      dateOfReceipt: $dateOfReceipt
      expectedDelivery: $expectedDelivery
      actualDelivery: $actualDelivery
      ticketStatus: $ticketStatus
      jobs: $jobs
    ) {
      id
      ticketDisplayId
      userId
      mobileNumber
      customerEmail
      customerName
      companyId
      notes
      dateOfReceipt
      expectedDelivery
      actualDelivery
      ticketStatus
      user {
        id
        name
        email
        mobileNumber
        companyId
        type
        keywords
        createdAt
        updatedAt
        __typename
      }
      company {
        id
        name
        website
        ownerId
        owner {
          id
          name
          email
          mobileNumber
          companyId
          type
          keywords
          createdAt
          updatedAt
          __typename
        }
        tickets {
          nextToken
          __typename
        }
        services {
          nextToken
          __typename
        }
        jobs {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      jobs
      jobsArray {
        items {
          id
          jobDisplayId
          userId
          companyId
          ticketId
          jobName
          numberOfRims
          brandOfRims
          sizeOfRims
          color
          matt
          brandOfTyre
          sizeOfTyre
          noOfCaps
          noOfTPMS
          makeOfVehicle
          vehicleNumberPlate
          notes
          imagesUrl
          dateOfReceipt
          expectedDelivery
          actualDelivery
          jobStatus
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      jobNames
      keywords
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const inviteMember = /* GraphQL */ `
  mutation InviteMember(
    $name: String!
    $email: String!
    $dpUrl: String
    $mobileNumber: String
    $companyId: ID!
  ) {
    inviteMember(
      name: $name
      email: $email
      dpUrl: $dpUrl
      mobileNumber: $mobileNumber
      companyId: $companyId
    ) {
      id
      role
      __typename
    }
  }
`;
export const emailSender = /* GraphQL */ `
  mutation EmailSender(
    $toEmail: String!
    $customerName: String!
    $appointmentStatus: String!
  ) {
    emailSender(
      toEmail: $toEmail
      customerName: $customerName
      appointmentStatus: $appointmentStatus
    ) {
      success
      message
      __typename
    }
  }
`;
export const deleteManagerUser = /* GraphQL */ `
  mutation DeleteManagerUser($id: ID!, $email: String!) {
    deleteManagerUser(id: $id, email: $email) {
      success
      message
      __typename
    }
  }
`;
export const managerResetPassword = /* GraphQL */ `
  mutation ManagerResetPassword($email: String!) {
    managerResetPassword(email: $email) {
      success
      message
      __typename
    }
  }
`;
export const createCompany = /* GraphQL */ `
  mutation CreateCompany(
    $input: CreateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    createCompany(input: $input, condition: $condition) {
      id
      name
      website
      ownerId
      owner {
        id
        name
        email
        mobileNumber
        companyId
        type
        keywords
        createdAt
        updatedAt
        __typename
      }
      tickets {
        items {
          id
          ticketDisplayId
          userId
          mobileNumber
          customerEmail
          customerName
          companyId
          notes
          dateOfReceipt
          expectedDelivery
          actualDelivery
          ticketStatus
          jobs
          jobNames
          keywords
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      services {
        items {
          id
          companyId
          name
          createdBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      jobs {
        items {
          id
          jobDisplayId
          userId
          companyId
          ticketId
          jobName
          numberOfRims
          brandOfRims
          sizeOfRims
          color
          matt
          brandOfTyre
          sizeOfTyre
          noOfCaps
          noOfTPMS
          makeOfVehicle
          vehicleNumberPlate
          notes
          imagesUrl
          dateOfReceipt
          expectedDelivery
          actualDelivery
          jobStatus
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCompany = /* GraphQL */ `
  mutation UpdateCompany(
    $input: UpdateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    updateCompany(input: $input, condition: $condition) {
      id
      name
      website
      ownerId
      owner {
        id
        name
        email
        mobileNumber
        companyId
        type
        keywords
        createdAt
        updatedAt
        __typename
      }
      tickets {
        items {
          id
          ticketDisplayId
          userId
          mobileNumber
          customerEmail
          customerName
          companyId
          notes
          dateOfReceipt
          expectedDelivery
          actualDelivery
          ticketStatus
          jobs
          jobNames
          keywords
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      services {
        items {
          id
          companyId
          name
          createdBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      jobs {
        items {
          id
          jobDisplayId
          userId
          companyId
          ticketId
          jobName
          numberOfRims
          brandOfRims
          sizeOfRims
          color
          matt
          brandOfTyre
          sizeOfTyre
          noOfCaps
          noOfTPMS
          makeOfVehicle
          vehicleNumberPlate
          notes
          imagesUrl
          dateOfReceipt
          expectedDelivery
          actualDelivery
          jobStatus
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCompany = /* GraphQL */ `
  mutation DeleteCompany(
    $input: DeleteCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    deleteCompany(input: $input, condition: $condition) {
      id
      name
      website
      ownerId
      owner {
        id
        name
        email
        mobileNumber
        companyId
        type
        keywords
        createdAt
        updatedAt
        __typename
      }
      tickets {
        items {
          id
          ticketDisplayId
          userId
          mobileNumber
          customerEmail
          customerName
          companyId
          notes
          dateOfReceipt
          expectedDelivery
          actualDelivery
          ticketStatus
          jobs
          jobNames
          keywords
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      services {
        items {
          id
          companyId
          name
          createdBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      jobs {
        items {
          id
          jobDisplayId
          userId
          companyId
          ticketId
          jobName
          numberOfRims
          brandOfRims
          sizeOfRims
          color
          matt
          brandOfTyre
          sizeOfTyre
          noOfCaps
          noOfTPMS
          makeOfVehicle
          vehicleNumberPlate
          notes
          imagesUrl
          dateOfReceipt
          expectedDelivery
          actualDelivery
          jobStatus
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      name
      email
      mobileNumber
      companyId
      type
      keywords
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      name
      email
      mobileNumber
      companyId
      type
      keywords
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      name
      email
      mobileNumber
      companyId
      type
      keywords
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAdminUser = /* GraphQL */ `
  mutation CreateAdminUser(
    $input: CreateAdminUserInput!
    $condition: ModelAdminUserConditionInput
  ) {
    createAdminUser(input: $input, condition: $condition) {
      id
      name
      email
      dpUrl
      mobileNumber
      companyId
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAdminUser = /* GraphQL */ `
  mutation UpdateAdminUser(
    $input: UpdateAdminUserInput!
    $condition: ModelAdminUserConditionInput
  ) {
    updateAdminUser(input: $input, condition: $condition) {
      id
      name
      email
      dpUrl
      mobileNumber
      companyId
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAdminUser = /* GraphQL */ `
  mutation DeleteAdminUser(
    $input: DeleteAdminUserInput!
    $condition: ModelAdminUserConditionInput
  ) {
    deleteAdminUser(input: $input, condition: $condition) {
      id
      name
      email
      dpUrl
      mobileNumber
      companyId
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createTicket = /* GraphQL */ `
  mutation CreateTicket(
    $input: CreateTicketInput!
    $condition: ModelTicketConditionInput
  ) {
    createTicket(input: $input, condition: $condition) {
      id
      ticketDisplayId
      userId
      mobileNumber
      customerEmail
      customerName
      companyId
      notes
      dateOfReceipt
      expectedDelivery
      actualDelivery
      ticketStatus
      user {
        id
        name
        email
        mobileNumber
        companyId
        type
        keywords
        createdAt
        updatedAt
        __typename
      }
      company {
        id
        name
        website
        ownerId
        owner {
          id
          name
          email
          mobileNumber
          companyId
          type
          keywords
          createdAt
          updatedAt
          __typename
        }
        tickets {
          nextToken
          __typename
        }
        services {
          nextToken
          __typename
        }
        jobs {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      jobs
      jobsArray {
        items {
          id
          jobDisplayId
          userId
          companyId
          ticketId
          jobName
          numberOfRims
          brandOfRims
          sizeOfRims
          color
          matt
          brandOfTyre
          sizeOfTyre
          noOfCaps
          noOfTPMS
          makeOfVehicle
          vehicleNumberPlate
          notes
          imagesUrl
          dateOfReceipt
          expectedDelivery
          actualDelivery
          jobStatus
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      jobNames
      keywords
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateTicket = /* GraphQL */ `
  mutation UpdateTicket(
    $input: UpdateTicketInput!
    $condition: ModelTicketConditionInput
  ) {
    updateTicket(input: $input, condition: $condition) {
      id
      ticketDisplayId
      userId
      mobileNumber
      customerEmail
      customerName
      companyId
      notes
      dateOfReceipt
      expectedDelivery
      actualDelivery
      ticketStatus
      user {
        id
        name
        email
        mobileNumber
        companyId
        type
        keywords
        createdAt
        updatedAt
        __typename
      }
      company {
        id
        name
        website
        ownerId
        owner {
          id
          name
          email
          mobileNumber
          companyId
          type
          keywords
          createdAt
          updatedAt
          __typename
        }
        tickets {
          nextToken
          __typename
        }
        services {
          nextToken
          __typename
        }
        jobs {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      jobs
      jobsArray {
        items {
          id
          jobDisplayId
          userId
          companyId
          ticketId
          jobName
          numberOfRims
          brandOfRims
          sizeOfRims
          color
          matt
          brandOfTyre
          sizeOfTyre
          noOfCaps
          noOfTPMS
          makeOfVehicle
          vehicleNumberPlate
          notes
          imagesUrl
          dateOfReceipt
          expectedDelivery
          actualDelivery
          jobStatus
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      jobNames
      keywords
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteTicket = /* GraphQL */ `
  mutation DeleteTicket(
    $input: DeleteTicketInput!
    $condition: ModelTicketConditionInput
  ) {
    deleteTicket(input: $input, condition: $condition) {
      id
      ticketDisplayId
      userId
      mobileNumber
      customerEmail
      customerName
      companyId
      notes
      dateOfReceipt
      expectedDelivery
      actualDelivery
      ticketStatus
      user {
        id
        name
        email
        mobileNumber
        companyId
        type
        keywords
        createdAt
        updatedAt
        __typename
      }
      company {
        id
        name
        website
        ownerId
        owner {
          id
          name
          email
          mobileNumber
          companyId
          type
          keywords
          createdAt
          updatedAt
          __typename
        }
        tickets {
          nextToken
          __typename
        }
        services {
          nextToken
          __typename
        }
        jobs {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      jobs
      jobsArray {
        items {
          id
          jobDisplayId
          userId
          companyId
          ticketId
          jobName
          numberOfRims
          brandOfRims
          sizeOfRims
          color
          matt
          brandOfTyre
          sizeOfTyre
          noOfCaps
          noOfTPMS
          makeOfVehicle
          vehicleNumberPlate
          notes
          imagesUrl
          dateOfReceipt
          expectedDelivery
          actualDelivery
          jobStatus
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      jobNames
      keywords
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createService = /* GraphQL */ `
  mutation CreateService(
    $input: CreateServiceInput!
    $condition: ModelServiceConditionInput
  ) {
    createService(input: $input, condition: $condition) {
      id
      companyId
      name
      createdBy
      company {
        id
        name
        website
        ownerId
        owner {
          id
          name
          email
          mobileNumber
          companyId
          type
          keywords
          createdAt
          updatedAt
          __typename
        }
        tickets {
          nextToken
          __typename
        }
        services {
          nextToken
          __typename
        }
        jobs {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateService = /* GraphQL */ `
  mutation UpdateService(
    $input: UpdateServiceInput!
    $condition: ModelServiceConditionInput
  ) {
    updateService(input: $input, condition: $condition) {
      id
      companyId
      name
      createdBy
      company {
        id
        name
        website
        ownerId
        owner {
          id
          name
          email
          mobileNumber
          companyId
          type
          keywords
          createdAt
          updatedAt
          __typename
        }
        tickets {
          nextToken
          __typename
        }
        services {
          nextToken
          __typename
        }
        jobs {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteService = /* GraphQL */ `
  mutation DeleteService(
    $input: DeleteServiceInput!
    $condition: ModelServiceConditionInput
  ) {
    deleteService(input: $input, condition: $condition) {
      id
      companyId
      name
      createdBy
      company {
        id
        name
        website
        ownerId
        owner {
          id
          name
          email
          mobileNumber
          companyId
          type
          keywords
          createdAt
          updatedAt
          __typename
        }
        tickets {
          nextToken
          __typename
        }
        services {
          nextToken
          __typename
        }
        jobs {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createJob = /* GraphQL */ `
  mutation CreateJob(
    $input: CreateJobInput!
    $condition: ModelJobConditionInput
  ) {
    createJob(input: $input, condition: $condition) {
      id
      jobDisplayId
      userId
      companyId
      ticketId
      jobName
      numberOfRims
      brandOfRims
      sizeOfRims
      color
      matt
      brandOfTyre
      sizeOfTyre
      noOfCaps
      noOfTPMS
      makeOfVehicle
      vehicleNumberPlate
      notes
      imagesUrl
      dateOfReceipt
      expectedDelivery
      actualDelivery
      jobStatus
      user {
        id
        name
        email
        mobileNumber
        companyId
        type
        keywords
        createdAt
        updatedAt
        __typename
      }
      company {
        id
        name
        website
        ownerId
        owner {
          id
          name
          email
          mobileNumber
          companyId
          type
          keywords
          createdAt
          updatedAt
          __typename
        }
        tickets {
          nextToken
          __typename
        }
        services {
          nextToken
          __typename
        }
        jobs {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      ticket {
        id
        ticketDisplayId
        userId
        mobileNumber
        customerEmail
        customerName
        companyId
        notes
        dateOfReceipt
        expectedDelivery
        actualDelivery
        ticketStatus
        user {
          id
          name
          email
          mobileNumber
          companyId
          type
          keywords
          createdAt
          updatedAt
          __typename
        }
        company {
          id
          name
          website
          ownerId
          createdAt
          updatedAt
          __typename
        }
        jobs
        jobsArray {
          nextToken
          __typename
        }
        jobNames
        keywords
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateJob = /* GraphQL */ `
  mutation UpdateJob(
    $input: UpdateJobInput!
    $condition: ModelJobConditionInput
  ) {
    updateJob(input: $input, condition: $condition) {
      id
      jobDisplayId
      userId
      companyId
      ticketId
      jobName
      numberOfRims
      brandOfRims
      sizeOfRims
      color
      matt
      brandOfTyre
      sizeOfTyre
      noOfCaps
      noOfTPMS
      makeOfVehicle
      vehicleNumberPlate
      notes
      imagesUrl
      dateOfReceipt
      expectedDelivery
      actualDelivery
      jobStatus
      user {
        id
        name
        email
        mobileNumber
        companyId
        type
        keywords
        createdAt
        updatedAt
        __typename
      }
      company {
        id
        name
        website
        ownerId
        owner {
          id
          name
          email
          mobileNumber
          companyId
          type
          keywords
          createdAt
          updatedAt
          __typename
        }
        tickets {
          nextToken
          __typename
        }
        services {
          nextToken
          __typename
        }
        jobs {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      ticket {
        id
        ticketDisplayId
        userId
        mobileNumber
        customerEmail
        customerName
        companyId
        notes
        dateOfReceipt
        expectedDelivery
        actualDelivery
        ticketStatus
        user {
          id
          name
          email
          mobileNumber
          companyId
          type
          keywords
          createdAt
          updatedAt
          __typename
        }
        company {
          id
          name
          website
          ownerId
          createdAt
          updatedAt
          __typename
        }
        jobs
        jobsArray {
          nextToken
          __typename
        }
        jobNames
        keywords
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteJob = /* GraphQL */ `
  mutation DeleteJob(
    $input: DeleteJobInput!
    $condition: ModelJobConditionInput
  ) {
    deleteJob(input: $input, condition: $condition) {
      id
      jobDisplayId
      userId
      companyId
      ticketId
      jobName
      numberOfRims
      brandOfRims
      sizeOfRims
      color
      matt
      brandOfTyre
      sizeOfTyre
      noOfCaps
      noOfTPMS
      makeOfVehicle
      vehicleNumberPlate
      notes
      imagesUrl
      dateOfReceipt
      expectedDelivery
      actualDelivery
      jobStatus
      user {
        id
        name
        email
        mobileNumber
        companyId
        type
        keywords
        createdAt
        updatedAt
        __typename
      }
      company {
        id
        name
        website
        ownerId
        owner {
          id
          name
          email
          mobileNumber
          companyId
          type
          keywords
          createdAt
          updatedAt
          __typename
        }
        tickets {
          nextToken
          __typename
        }
        services {
          nextToken
          __typename
        }
        jobs {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      ticket {
        id
        ticketDisplayId
        userId
        mobileNumber
        customerEmail
        customerName
        companyId
        notes
        dateOfReceipt
        expectedDelivery
        actualDelivery
        ticketStatus
        user {
          id
          name
          email
          mobileNumber
          companyId
          type
          keywords
          createdAt
          updatedAt
          __typename
        }
        company {
          id
          name
          website
          ownerId
          createdAt
          updatedAt
          __typename
        }
        jobs
        jobsArray {
          nextToken
          __typename
        }
        jobNames
        keywords
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createMetaData = /* GraphQL */ `
  mutation CreateMetaData(
    $input: CreateMetaDataInput!
    $condition: ModelMetaDataConditionInput
  ) {
    createMetaData(input: $input, condition: $condition) {
      id
      type
      name
      additionalInfo
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateMetaData = /* GraphQL */ `
  mutation UpdateMetaData(
    $input: UpdateMetaDataInput!
    $condition: ModelMetaDataConditionInput
  ) {
    updateMetaData(input: $input, condition: $condition) {
      id
      type
      name
      additionalInfo
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteMetaData = /* GraphQL */ `
  mutation DeleteMetaData(
    $input: DeleteMetaDataInput!
    $condition: ModelMetaDataConditionInput
  ) {
    deleteMetaData(input: $input, condition: $condition) {
      id
      type
      name
      additionalInfo
      createdAt
      updatedAt
      __typename
    }
  }
`;
