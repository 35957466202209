import api from "../../../api";
import { types } from "../../types";

export default function deleteUserAction(values) {
  return async function deleteUserActionThunk(dispatch) {
    dispatch({ type: types.user.delete.started, payload: false });
    try {
      const data = await api.user.delete(values);
      dispatch({ type: types.user.delete.success, payload: data });
    } catch (error) {
      dispatch({ type: types.user.delete.failed, payload: error });
    }
    dispatch({ type: types.user.delete.reset });
  };
}
