import api from "../../../api";
import { types } from "../../types";

export default function signOutAction(values) {
  return async function signOutActionThunk(dispatch) {
    dispatch({ type: types.auth.signOut.started, payload: false });
    try {
      const data = await api.auth.signOut(values);
      dispatch({ type: types.auth.signOut.success, payload: data });
    } catch (error) {
      dispatch({ type: types.auth.signOut.failed, payload: error });
    }
    dispatch({ type: types.auth.signOut.reset });
  };
}
