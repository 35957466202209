import { Button, Form, message, Popover, Table, Input, Select } from "antd";
import { useEffect, useState } from "react";
import { useHistory, Route, useLocation } from "react-router";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { ac } from "../../store/actionCreators";
import classNames from "./dashboard.module.scss";
import CreateTicketModal from "./CreateTicketModal/CreateTicketModal";
import UpdateTicketModal from "./UpdateTicketModal/UpdateTicketModal";
import Notes from "./Notes";
import {
  MdDashboard,
  // MdOutlineNotes,
  MdRefresh,
  MdSupervisedUserCircle,
  // MdCreate,
  MdOutlinePeople,
  MdOutlineAddCircleOutline,
  MdSettings,
} from "react-icons/md";
import UserManagement from "./UserManagement";
import { useFetchUser } from "../../hooks/useFetchUser";
import CustomerManagement from "./CustomerManagement";
import { data } from "../../data/data";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import api from "../../api";
import Settings from "./Settings";

const Dashboard = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const [status, setStatus] = useState();
  const [formCompleteNewPassword] = Form.useForm();
  const [visibleCreateTicketModal, setVisibleCreateTicketModal] = useState();
  const [visibleUpdateTicketModal, setVisibleUpdateTicketModal] = useState();
  const [viewRecordId, setRecordId] = useState(null);
  const [keyword, setKeyword] = useState();
  const history = useHistory();
  const { Option } = Select;
  const loc = useLocation();
  const currentUser = useFetchUser();
  const success = useSelector(function ({ deleteTicket }) {
    return deleteTicket.success;
  });

  const error = useSelector(function ({ deleteTicket }) {
    return deleteTicket.error;
  });

  const fetching = useSelector(function ({ deleteTicket }) {
    return deleteTicket.fetching;
  });

  // const fetchingListTicketsByCompanyId = useSelector(function ({
  //   listTicketsByCompanyId,
  // }) {
  //   return listTicketsByCompanyId.fetching;
  // });

  const signedIn = useSelector(function ({ signIn }) {
    return signIn.success;
  });

  const { challengeName } = useSelector(function ({ signIn: { data } }) {
    return data ? data : {};
  });

  const completeNewPasswordFetching = useSelector(function ({
    completeNewPassword,
  }) {
    return completeNewPassword.fetching;
  });
  const cognitoUserObj = useSelector(function ({ signIn: { data } }) {
    return data ? data : {};
  });

  const onFinishCompleteNewPassword = (values) => {
    dispatch(ac.completeNewPassword(cognitoUserObj, values));
  };

  const onClick = () => {
    queryClient.invalidateQueries("getTicketsByCompanyId");
    form.resetFields();
  };

  const onClickView = (text, record) => {
    setVisibleUpdateTicketModal(true);
    setRecordId(record.ticketId);
  };

  const onClickDelete = (text, record) => {
    let ticketRecord =
      ticketList.items &&
      ticketList.items.length > 0 &&
      ticketList.items.find((element) => element.id === record.ticketId);

    if (ticketRecord && !ticketRecord?.jobs) {
      return;
    }

    for (let index = 0; index < ticketRecord?.jobs.length; index++) {
      const element = ticketRecord?.jobs[index];
      dispatch(ac.deleteJob({ id: element }));
    }
    dispatch(ac.deleteTicket({ id: record.ticketId }));
  };

  const { data: ticketListData, isLoading: fetchingListTicketsByCompanyId } =
    useQuery(["getTicketsByCompanyId"], () => api.ticket.list());

  const { data: customerListData } = useQuery(
    ["getCustomersByCompanyId"],
    () => api.user.getUsersByCompany(),
    {
      enabled: !!currentUser,
    },
  );

  const { data: metadData } = useQuery(
    ["getmetadataByCompanyId"],
    () => api.metaData.list(),
    {
      enabled: !!currentUser,
      staleTime: 5 * 60 * 1000, // 5 minutes
      cacheTime: 30 * 60 * 1000, // 30 minutes
    },
  );

  const userList =
    customerListData?.data?.usersByCompanyAndCreatedAt?.items || [];
  const ticketList =
    ticketListData?.data?.listTicketsByCompanyAndCreatedAt?.items;
  // const userList = useSelector(function ({ getUsersByCompanyId }) {
  //   return getUsersByCompanyId.data
  //     ? getUsersByCompanyId.data
  //       ? getUsersByCompanyId.data.getUsersByCompanyId
  //       : []
  //     : [];
  // });
  const { data: adminUserData } = useQuery(
    ["getAdminUsers"],
    () => api.auth.listAdminUsers(),
    {
      enabled: !!currentUser,
    },
  );
  const adminUserList = adminUserData?.data?.listAdminUsers?.items || [];
  const userData = (id) => {
    return userList && userList.length > 0
      ? userList.find((user) => user.id === id)
      : undefined;
  };

  const onClickCreateTicketModal = () => {
    setVisibleCreateTicketModal(true);
  };

  const dataSource =
    ticketList && ticketList && ticketList.length > 0
      ? ticketList.map((item, index) => {
          // console.log("item: ", item);
          let jobs = Array.isArray(item.jobs) ? item.jobs : [];
          let jobsArray = Array.isArray(item.jobsArray.items)
            ? item.jobsArray.items
            : [];
          // console.log("jobs: ", jobs);
          // console.log("jobsArray: ", jobsArray);
          // find values in jobsArray that are in jobs
          let jobsFiltered = [];
          jobs.forEach((job) => {
            if (jobsArray.length === 0) {
              jobsFiltered.push({
                id: job,
                ticketId: item.id,
                STATUS: "TO_BE_UPDATE",
              });
            } else {
              jobsArray.filter((jobArray) =>
                jobArray.id.trim() === job.trim()
                  ? jobsFiltered.push({ ...jobArray, STATUS: "ADDED" })
                  : jobsFiltered.push({
                      id: job,
                      ticketId: item.id,
                      STATUS: "TO_BE_UPDATE",
                    }),
              );
            }
          });

          // jobsFiltered.reduce((acc, current) => {
          //   let x = acc.find(item => item.id === current.id);
          //   if (!x) {
          //     return acc.concat([current]);
          //   } else {
          //     if (current.STATUS !== 'TO_BE_UPDATE') {
          //       let index = acc.indexOf(x);
          //       acc[index] = current;
          //     }
          //     return acc;
          //   }
          // }, []);
          // console.log("jobsFiltered: ", jobsFiltered);

          return {
            key: index,
            ticketId: item.id,
            customerName: item.userId
              ? userData(item.userId)?.name
              : item.userId,
            customerEmail: item.userId
              ? userData(item.userId)?.email
              : item.userId,
            customerMobileNumber: item.userId
              ? userData(item.userId)?.mobileNumber
              : item.userId,
            jobArray: jobsFiltered,
            jobs: item?.jobs && item.jobs.length > 0 ? item.jobs : [],
            // rimBrandSize: item?.jobs && item.jobs.items && item.jobs.items.length > 0 ? item.jobs.items.map(item => `${item.numberOfRims} ${item.brandOfRims} rims of size: ${item.sizeOfRims}`) : [],
            createdAt: item.dateOfReceipt,
            expectedDelivery: item.expectedDelivery,
            actualDelivery: item.actualDelivery,
            status: item.ticketStatus,
            // Revert back to line 161 as requested by the client.
            // Uncomment below line (164) if client need that back
            // status: moment().isBefore(item.expectedDelivery, "day")
            // ? item.ticketStatus
            // : "Delayed",
          };
        })
      : [];

  const columns = [
    {
      title: "Ticket Id",
      dataIndex: "ticketId",
      key: "ticketId",
      //   fixed: "left",
      render: (text, record) => {
        return <div>{text.split("+")[1]}</div>;
      },
      sorter: (a, b) => a.key - b.key,
    },
    {
      title: "Name",
      dataIndex: "customerName",
      key: "customerName",
      fixed: "left",
      responsive: ["lg"],
      sorter: (a, b) =>
        a.customerName && a.customerName.localeCompare(b.customerName),
      //   width: "30%"
    },
    // {
    //   title: "Email",
    //   dataIndex: "customerEmail",
    //   key: "customerEmail",
    //   responsive: ["lg"],
    // },
    {
      title: "Mobile Number",
      dataIndex: "customerMobileNumber",
      key: "customerMobileNumber",
      responsive: ["lg"],
    },
    {
      title: "Jobs",
      dataIndex: "jobArray",
      key: "jobArray",
      responsive: ["lg"],
      render: (text, record) => {
        // Separate jobs into two arrays based on their status
        const addedJobs = text.filter((job) => job.STATUS === "ADDED");
        const toBeUpdatedJobs = text.filter(
          (job) => job.STATUS === "TO_BE_UPDATE",
        );

        // If there are no jobs to show, render a message
        if (addedJobs.length === 0 && toBeUpdatedJobs.length === 0) {
          return <div>No jobs to show</div>;
        }

        return (
          <div>
            {addedJobs.map((job, index) => (
              <div key={index} style={{ marginBottom: "4%" }}>
                <span>
                  <b> {job.jobName}</b>
                </span>
                <p>
                  {job.numberOfRims} x {job.sizeOfRims} {job.brandOfRims}
                </p>
              </div>
            ))}
            {toBeUpdatedJobs.map((job, index) => {
              // Check if there is an added job with the same ID
              const hasAddedJobWithSameId = addedJobs.some(
                (addedJob) => addedJob.id === job.id,
              );

              // Only render the button if there is no added job with the same ID
              if (!hasAddedJobWithSameId) {
                return (
                  <div key={index}>
                    <small>A job remaining to update.</small>
                    <Button
                      type="primary"
                      onClick={() => {
                        let input = {
                          id: job.id,
                          ticketId: job.ticketId,
                        };
                        dispatch(ac.updateJob(input));
                        queryClient.invalidateQueries("getTicketsByCompanyId");
                      }}
                    >
                      Click to update!
                    </Button>
                  </div>
                );
              }
              return null;
            })}
          </div>
        );
      },
    },
    {
      title: "Date In",
      dataIndex: "createdAt",
      key: "createdAt",
      responsive: ["lg"],
      render: (text, record) => {
        return <div>{moment(text).format("DD/MM/YYYY")}</div>;
      },
      sorter: (a, b) => moment(a.createdAt) - moment(b.createdAt),
    },
    {
      title: "Expected Date Out",
      dataIndex: "expectedDelivery",
      key: "expectedDelivery",
      responsive: ["lg"],
      sorter: (a, b) => moment(a.expectedDelivery) - moment(b.expectedDelivery),
      render: (text, record) => {
        return (
          <div>
            {!text
              ? "Need to update the date"
              : moment(text).format("DD/MM/YYYY")}
          </div>
        );
      },
    },
    {
      title: "Date Out",
      dataIndex: "actualDelivery",
      key: "actualDelivery",
      responsive: ["lg"],
      sorter: (a, b) => moment(a.actualDelivery) - moment(b.actualDelivery),
      render: (text, record) => {
        if (record.status !== data.status[3]) return;
        return (
          <div>
            {!text
              ? "Need to update the date"
              : moment(text).format("DD/MM/YYYY")}
          </div>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      fixed: "right",
      render: (text, record) => {
        return (
          <div>
            <Button type="primary" onClick={() => onClickView(text, record)}>
              View/Update
            </Button>
            {currentUser?.type === "admin" && (
              <Popover
                title="Are you sure?"
                content={
                  <Button
                    type="primary"
                    loading={fetching}
                    onClick={() => onClickDelete(text, record)}
                  >
                    Yes
                  </Button>
                }
              >
                <Button type="primary">Delete</Button>
              </Popover>
            )}
          </div>
        );
      },
    },
  ];

  const onFinish = (values) => {
    var obj;
    if (values["category"] === "userId") {
      obj = {
        userId: values["input"],
      };
    } else if (values["category"] === "ticketDisplayId") {
      obj = {
        ticketDisplayId: values["input"],
      };
    } else if (values["category"] === "customerName") {
      obj = {
        customerName: values["input"],
      };
    } else if (values["category"] === "mobileNumber") {
      obj = {
        mobileNumber: values["input"],
      };
    } else {
      obj = {
        ticketDisplayId: values["input"],
      };
    }
    dispatch(ac.listTicketsByCompanyId(obj));
  };

  useEffect(() => {
    if (success) {
      queryClient.invalidateQueries("getTicketsByCompanyId");
      // setVisible(false);
      message.success("Ticket deleted successfully!");
    }
    if (error) {
      message.error("Error!");
    }
  }, [success, error, dispatch, queryClient]);
  return (
    <div>
      {signedIn && challengeName === "NEW_PASSWORD_REQUIRED" && (
        <div className={classNames.completeNewPassword}>
          <p>New password required! Please update your temporary password.</p>
          <Form
            form={formCompleteNewPassword}
            layout="inline"
            onFinish={onFinishCompleteNewPassword}
          >
            <Form.Item name="newPassword" label="New Password">
              <Input />
            </Form.Item>
            <Form.Item>
              <Button
                loading={completeNewPasswordFetching}
                type="primary"
                htmlType="submit"
              >
                Update Password
              </Button>
            </Form.Item>
          </Form>
        </div>
      )}
      <div className={classNames.wrapper}>
        {/* <h2>Dashboard</h2>
        <div> */}
        <aside className={classNames.aside}>
          <ul>
            {/*Add new ticket button due to the CR-UAT */}
            <li onClick={onClickCreateTicketModal}>
              <MdOutlineAddCircleOutline color="green" size="35px" />
            </li>
            {/*Remove unused buttons due to the CR-UAT */}
            <li onClick={() => history.push("/dashboard")}>
              <MdDashboard size="30px" />
              <div>Dashboard</div>
            </li>
            {/* <li onClick={() => history.push("/dashboard/notes")}>
              <MdOutlineNotes size="30px" /><div>Dashboard</div>
            </li> */}
            <li onClick={() => history.push("/dashboard/customer-management")}>
              <MdOutlinePeople size="30px" />
              <div>Customers</div>
            </li>
            {currentUser?.type === "admin" && (
              <>
                <li onClick={() => history.push("/dashboard/user-management")}>
                  <MdSupervisedUserCircle size="30px" />
                  <div>User Management</div>
                </li>
                <li onClick={() => history.push("/dashboard/settings")}>
                  <MdSettings size="30px" />
                  <div>Settings</div>
                </li>
              </>
            )}
            {/* <li onClick={() => history.push("/create-ticket")}>
              <MdCreate size="30px" /><div>Dashboard</div>
            </li> */}
          </ul>
        </aside>
        <section className={classNames.content}>
          <Route path={`/dashboard/notes`} component={() => <Notes />} />
          {currentUser?.type === "admin" && (
            <>
              <Route
                path={`/dashboard/user-management`}
                component={() => (
                  <UserManagement adminUserList={adminUserList} />
                )}
              />
              <Route
                path={`/dashboard/settings`}
                component={() => (
                  <Settings
                    metadata={metadData?.data?.listMetaDatas?.items || []}
                  />
                )}
              />
            </>
          )}
          <Route
            path={`/dashboard/customer-management`}
            component={() => (
              <CustomerManagement adminUserList={adminUserList} />
            )}
          />{" "}
          {loc && loc.pathname === "/dashboard" ? (
            <div>
              <h2>Dashboard</h2>
              <div className={classNames.header}>
                {/* <Button type="primary" onClick={onClick}> */}
                <MdRefresh size="30px" onClick={onClick} />
                {/* </Button> */}
                <Button type="primary" onClick={onClickCreateTicketModal}>
                  Create New Ticket
                </Button>
                <Form
                  className={classNames.searchForm}
                  form={form}
                  onFinish={onFinish}
                >
                  <Input.Group compact>
                    <Form.Item name="category">
                      <Select defaultValue="ticketDisplayId" bordered>
                        <Option value="customerName">Name</Option>
                        {/* Remove search by user id option as client requested
                        Ticket id: https://mysn.atlassian.net/browse/TS-137 */}
                        {/* <Option value="userId">Email</Option> */}
                        <Option value="ticketDisplayId">Ticket Id</Option>
                        <Option value="mobileNumber">Mobile Number</Option>
                      </Select>
                    </Form.Item>
                    <Form.Item name="input">
                      <Input
                        allowClear
                        onChange={(e) => setKeyword(e.target.value)}
                      />
                    </Form.Item>
                  </Input.Group>
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      Search
                    </Button>
                  </Form.Item>
                </Form>
                <div>
                  <Select
                    placeholder="Ticket status"
                    allowClear
                    style={{ width: 150 }}
                    defaultValue={data.status[0]}
                    bordered
                    onChange={(e) => setStatus(e)}
                  >
                    {data.status.map((num, index) => {
                      return (
                        <Select.Option value={num} key={index}>
                          {num}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </div>
              </div>
              <Table
                className={classNames.tableWrapper}
                dataSource={
                  keyword
                    ? dataSource &&
                      dataSource.length > 0 &&
                      dataSource
                        .filter((obj) => {
                          let filterObj = {
                            id: obj.ticketId.split("+")[1],
                          };
                          if (
                            form.getFieldValue(["category"]) === "mobileNumber"
                          ) {
                            filterObj = {
                              mobileNumber: obj.customerMobileNumber,
                            };
                          }
                          if (form.getFieldValue(["category"]) === "userId") {
                            filterObj = {
                              userId: obj.customerEmail,
                            };
                          }
                          if (
                            form.getFieldValue(["category"]) === "customerName"
                          ) {
                            filterObj = {
                              userId: obj.customerName,
                            };
                          }
                          // remove toLowerCase() method due to the case sensitive issue in the dynamoDB
                          // update again with toLowerCase() function to balance client: 1/22/2022
                          // TODO: Dynamodb not offering case sensitive. Hence currently pointing on to frontend search
                          return Object.values(filterObj)
                            .toString()
                            .toLowerCase()
                            .includes(keyword.toLowerCase());
                          // .includes(keyword)
                        })
                        .filter((value) =>
                          status === "All"
                            ? value
                            : status
                            ? value.status === status
                            : value,
                        )
                    : dataSource.filter((value) =>
                        status === "All"
                          ? value
                          : status
                          ? value.status === status
                          : value,
                      )
                }
                pagination={{
                  pageSize: 25,
                }}
                columns={columns}
                scroll={{ x: 150 }}
                sticky
                loading={fetchingListTicketsByCompanyId}
              />
              <UpdateTicketModal
                visible={visibleUpdateTicketModal}
                recordId={viewRecordId}
                setVisible={setVisibleUpdateTicketModal}
                metadata={metadData?.data?.listMetaDatas?.items || []}
              />
            </div>
          ) : null}
        </section>
        <CreateTicketModal
          visible={visibleCreateTicketModal}
          // recordId={viewRecordId}
          setVisible={setVisibleCreateTicketModal}
          userList={userList}
          metadata={metadData?.data?.listMetaDatas?.items || []}
        />
        {/* </div> */}
      </div>
    </div>
  );
};

export default Dashboard;
