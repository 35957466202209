export const transformMetadata = (metadata) => {
  if (!metadata || metadata.length === 0) return {};

  // Group metadata by type
  const groupedData = metadata.reduce((acc, item) => {
    const type = item.type;
    const name = item.name;

    if (!acc[type]) {
      acc[type] = [];
    }
    acc[type].push(name);

    // Handle subFields dynamically for "servicesWithDueDates"
    if (type === "services" && item.additionalInfo) {
      const additionalInfo = JSON.parse(item.additionalInfo);
      if (additionalInfo.servicesWithDueDates) {
        if (!acc.servicesWithDueDates) {
          acc.servicesWithDueDates = [];
        }
        acc.servicesWithDueDates.push({
          name,
          days: parseInt(additionalInfo.servicesWithDueDates, 10),
        });
      }
    }

    return acc;
  }, {});

  // Sort each metadata group alphanumerically
  Object.keys(groupedData).forEach((key) => {
    groupedData[key] = groupedData[key].sort((a, b) => {
      // For objects like `servicesWithDueDates`, compare `name`
      if (typeof a === "object" && typeof b === "object") {
        return a.name.localeCompare(b.name, undefined, { numeric: true });
      }
      // For simple strings, compare directly
      return a.localeCompare(b, undefined, { numeric: true });
    });
  });

  // Add static 'status' data
  groupedData.status = [
    "All",
    "In-Progress",
    "Completed",
    "Completed & Delivered",
    // Uncomment below line if the client requires it in the future
    // "Delayed",
  ];

  return groupedData;
};
