import api from "../../../api";
import { types } from "../../types";

export default function inviteMemberAction(values, array) {
  return async function inviteMemberActionThunk(dispatch) {
    dispatch({ type: types.auth.inviteMember.started, payload: false });
    try {
      const data = await api.auth.inviteMember(values, array);
      dispatch({ type: types.auth.inviteMember.success, payload: data });
    } catch (error) {
      dispatch({ type: types.auth.inviteMember.failed, payload: error });
    }
    dispatch({ type: types.auth.inviteMember.reset });
  };
}
