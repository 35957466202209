import { API, graphqlOperation } from "@aws-amplify/api";
import { deleteMetaData } from "../../graphql/mutations";

export default async function deleteMeatDataApi(input) {
  const variables = {
    input,
  };

  return await API.graphql(graphqlOperation(deleteMetaData, variables));
}
