import { Button, Form, Input, message } from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { ac } from "../../store/actionCreators";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";

const SignIn = ({ setVisible }) => {
  const [form] = Form.useForm();
  const [formCompleteNewPassword] = Form.useForm();
  const [formResetPassword] = Form.useForm();

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const { from } = location.state || { from: { pathname: "/" } };
  const { username, challengeName } = useSelector(function ({
    signIn: { data },
  }) {
    return data ? data : {};
  });

  // Track to check the Password Reset exceptiopn
  const signInError = useSelector(function ({ signIn }) {
    return signIn;
  });

  const cognitoUserObj = useSelector(function ({ signIn: { data } }) {
    return data ? data : {};
  });
  const onFinish = (values) => {
    dispatch(ac.signIn(values));
  };

  const fetching = useSelector(function ({ signIn }) {
    return signIn.fetching;
  });

  const signedIn = useSelector(function ({ signIn }) {
    return signIn.success;
  });
  const completeNewPassword = useSelector(function ({ completeNewPassword }) {
    return completeNewPassword.success;
  });
  const completeNewPasswordFetching = useSelector(function ({
    completeNewPassword,
  }) {
    return completeNewPassword.fetching;
  });

  const onFinishCompleteNewPassword = (values) => {
    dispatch(ac.completeNewPassword(cognitoUserObj, values));
  };
  useEffect(() => {
    if (signedIn) {
      dispatch(ac.getUsersByCompanyId());
      dispatch(ac.fetchCurrentUser());
      history.push("dashboard");
    }
    if (
      !signedIn &&
      cognitoUserObj &&
      cognitoUserObj.message === "Incorrect username or password."
    ) {
      message.error(cognitoUserObj.message);
    }
  }, [cognitoUserObj, dispatch, history, signedIn]);

  useEffect(() => {
    if (completeNewPassword) {
      message.success("Password updated successfully");
      history.push("dashboard");
    }
  }, [completeNewPassword, history]);
  useEffect(() => {
    if (!!username) {
      // Bye
      // history.replace(from);
    }
  }, [username, history, from]);

  const forgotPasswordSubmitFetching = useSelector(function ({
    forgotPasswordSubmit,
  }) {
    return forgotPasswordSubmit.fetching;
  });

  const forgotPasswordSubmitFn = (data) => {
    let input = {
      username: form.getFieldValue("username"),
      code: data.code,
      new_password: data.new_password,
    };
    dispatch(ac.forgotPasswordSubmit(input))
      .then(() => {
        formResetPassword.resetFields();
        setVisible(false);
        history.push("/");
        window.location.reload();
      })
      .catch((err) => {
        console.log("err", err);
        message.error("Error");
      });
  };
  if (signInError.data === "PasswordResetRequiredException") {
    return (
      <div>
        <p>We have sent a verfication code to {username}</p>
        <Form form={formResetPassword} onFinish={forgotPasswordSubmitFn}>
          <Form.Item name="code" label="Code">
            <Input />
          </Form.Item>
          <Form.Item name="new_password" label="New Password">
            <Input />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={forgotPasswordSubmitFetching}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }

  return (
    <div>
      {!signedIn ? (
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <h2>Sign In</h2>
          <Form.Item name="username" label="Username">
            <Input />
          </Form.Item>
          <Form.Item name="password" label="Password">
            <Input.Password
              placeholder="Please Enter Password"
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
            />
          </Form.Item>
          <Form.Item>
            <Button loading={fetching} type="primary" htmlType="submit">
              Sign In
            </Button>
            <Button
              onClick={() => {
                setVisible(false);
                history.push("/forgot-password");
              }}
              type="ghost"
            >
              Forgot Password
            </Button>
          </Form.Item>
        </Form>
      ) : (
        "Logged In"
      )}
      {signedIn && challengeName === "NEW_PASSWORD_REQUIRED" && (
        <Form
          form={formCompleteNewPassword}
          layout="vertical"
          onFinish={onFinishCompleteNewPassword}
        >
          <Form.Item name="newPassword" label="newPassword">
            <Input.Password
              placeholder="Please Enter A New Password"
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
            />
          </Form.Item>
          <Form.Item>
            <Button
              loading={completeNewPasswordFetching}
              type="primary"
              htmlType="submit"
            >
              Update password
            </Button>
          </Form.Item>
        </Form>
      )}
    </div>
  );
};

export default SignIn;
