import API from "@aws-amplify/api";
import { jobsBatchGet } from "../../graphql/queries";

export default async function jobsBatchGetApi(data) {
  let jobIds = data;
  return await API.graphql({
    query: jobsBatchGet,
    variables: { jobIds },
  });
}
