import api from "../../../api";
import { types } from "../../types";

export default function fetchCurrentUserAction() {
  return async function fetchCurrentUserActionThunk(dispatch) {
    dispatch({ type: types.auth.fetchCurrentUser.started, payload: false });
    try {
      const data = await api.auth.fetchCurrentUser();
      dispatch({ type: types.auth.fetchCurrentUser.success, payload: data });
    } catch (error) {
      dispatch({ type: types.auth.fetchCurrentUser.failed, payload: error });
    }
    // dispatch({ type: types.auth.fetchCurrentUser.reset });
  };
}
