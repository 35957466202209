/* eslint-disable default-case */
import { types } from "../../types";

const initalState = {
  fetching: false,
  success: false,
  data: false,
};
export default function signOutReducer(state = initalState, action) {
  switch (action.type) {
    case types.auth.signOut.started:
      return { fetching: true, success: false, data: false };
    case types.auth.signOut.success:
      return { fetching: false, success: true, data: action.payload };
    case types.auth.signOut.failed:
      return { fetching: false, success: false, data: action.payload };
    case types.auth.signOut.reset:
      return {
        fetching: false,
        success: false,
        data: false,
      };
  }
  return state;
}
