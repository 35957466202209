/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
import Storage from "@aws-amplify/storage";
import { useDispatch, useSelector } from "react-redux";
import {
  DatePicker,
  Modal,
  Button,
  Upload,
  Form,
  Input,
  Select,
  message,
  Space,
  // Switch,
  AutoComplete,
  Divider,
} from "antd";
import {
  MinusCircleOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { useQuery, useQueryClient } from "@tanstack/react-query";

import { ac } from "../../../store/actionCreators";
import classNames from "./createNewTicketModal.module.scss";
import { transformMetadata } from "../../../utils/transformMetadata";
import api from "../../../api";

export default function CreateTicketModal({
  visible,
  recordId,
  setVisible,
  userList,
  metadata,
}) {
  const data = transformMetadata(metadata);

  const dispatch = useDispatch();
  const autoCompletRef = useRef(null);

  const queryClient = useQueryClient();

  const [form] = Form.useForm();
  const [formCurrentValues, setFormCurrentValues] = useState();
  const [existingCustomer, setExistingCustomer] = useState(false);
  // const [userFetching, setUserFetching] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [newColor, setNewColor] = useState("");
  let colorList = data.colors;

  const currentCustomerUserEmail = formCurrentValues
    ? formCurrentValues.email
    : undefined;
  const currentCustomerUserMobileNumber = formCurrentValues
    ? formCurrentValues.mobileNumber
    : undefined;

  let companyId = "oslofelgretting.no";

  const success = useSelector(function ({ createTicket }) {
    return createTicket.success;
  });
  const error = useSelector(function ({ createTicket }) {
    return createTicket.error;
  });
  const fetching = useSelector(function ({ createTicket }) {
    return createTicket.fetching;
  });

  const { data: getUserByEmailData } = useQuery(
    ["getUser", currentCustomerUserMobileNumber],
    () =>
      api.user.byEmail({
        companyId: companyId,
        mobileNumber: currentCustomerUserMobileNumber,
      }),
    {
      enabled: !!currentCustomerUserMobileNumber,
    },
  );

  const usersOptions = userList?.map((val) => {
    return { value: val.id, label: val.name };
  });

  const customerUserConfirmData = getUserByEmailData?.data?.getUserByEmail;

  const generateServicesList = (index, value, flag) => {
    if (flag === "service") {
      let values = form.getFieldsValue();
      let obj = values["services"][index];
      let numberOfDatesToAdd = data.servicesWithDueDates.find(
        (val) => val.name === value,
      ).days;
      let tempExpectedDeliveryDate = moment().add(numberOfDatesToAdd, "days");
      Object.assign(obj, { expectedDelivery: tempExpectedDeliveryDate });
    }
  };

  const onFinish = async (values) => {
    let servicesList = values ? values.services : [];
    if (!servicesList) {
      message.error("Please add one or more tickets!");
      return;
    }
    if (
      !existingCustomer &&
      Array.isArray(customerUserConfirmData) &&
      customerUserConfirmData.length > 0
    ) {
      message.warning(
        `${customerUserConfirmData[0]?.name} is a registered customer`,
      );
      return;
    }
    if (existingCustomer && !customerUserConfirmData) {
      message.error(
        "Customer not found. Please add contact information to proceed!",
      );
      return;
    }

    setUploading(true);
    for (let index = 0; index < servicesList.length; index++) {
      let element = servicesList[index];
      if (element && element.service === "Refurbish") {
        element["jobDisplayId"] = `${index}R`;
      } else element["jobDisplayId"] = `${index}`;

      if (
        element &&
        element.image &&
        element.image.fileList &&
        element.image.fileList.length > 0
      ) {
        let fileList = element.image.fileList;
        let array = [];
        for (let index = 0; index < fileList.length; index++) {
          const imageFile = fileList[index].originFileObj;
          let id = `${imageFile.uid}-${uuidv4()}-${index}`;
          await Storage.put(id, imageFile, {
            level: "public",
            contentType: imageFile.type,
          })
            .then((data) => array.push(data.key))
            .catch((error) =>
              console.log(index + ". Image Upload Error: ", error),
            );
        }
        element["imagesUrl"] = JSON.stringify(array);
      }
      delete element["image"];
    }
    setUploading(false);

    const userId = uuidv4();
    if (
      !existingCustomer &&
      customerUserConfirmData &&
      !customerUserConfirmData.length
    ) {
      let userData = {
        input: {
          id: userId,
          name: values.customerName,
          email: values.email,
          mobileNumber: values.mobileNumber,
          companyId: companyId,
          type: "customer",
        },
      };
      dispatch(ac.createUser(userData));
    }
    values["mobileNumber"] =
      Array.isArray(customerUserConfirmData) &&
      customerUserConfirmData.length > 0
        ? customerUserConfirmData[0].mobileNumber
        : values.mobileNumber;
    dispatch(
      ac.createTicket({
        values,
        userId: customerUserConfirmData[0]?.id || userId,
        customerName: customerUserConfirmData[0]?.name || values.customerName,
        customerEmail: customerUserConfirmData[0]?.email || values.email,
        customerMobileNumber:
          customerUserConfirmData[0]?.mobileNumber || values.mobileNumber,
      }),
    );
    setExistingCustomer(false);
  };

  function disabledDate(current) {
    return current && current < moment().startOf("day");
  }

  useEffect(() => {
    if (success) {
      dispatch(ac.getUsersByCompanyId());
      queryClient.invalidateQueries("getTicketsByCompanyId");
      // dispatch(ac.listTicketsByCompanyId());
      form.resetFields();
      setVisible(false);
      message.success("Ticket created successfully!");
    }
    if (error) {
      message.error("Error!");
    }
  }, [success, error, setVisible, dispatch, form, queryClient]);

  useEffect(() => {
    // setUserFetching(true);
    if (currentCustomerUserEmail) {
      dispatch(
        ac.getUserByEmail({
          companyId: companyId,
          email: currentCustomerUserEmail,
        }),
      );
    }
    // setUserFetching(false);
  }, [dispatch, currentCustomerUserEmail, companyId]);

  useEffect(() => {
    // setUserFetching(true);
    if (currentCustomerUserMobileNumber) {
      dispatch(
        ac.getUserByEmail({
          companyId: companyId,
          mobileNumber: currentCustomerUserMobileNumber,
        }),
      );
    }
    // setUserFetching(false);
  }, [dispatch, currentCustomerUserMobileNumber, companyId]);

  useEffect(() => {
    let services;
    let minimumNumber;
    let furthestDate;
    if (
      formCurrentValues?.services &&
      formCurrentValues?.services?.length > 0
    ) {
      services =
        formCurrentValues?.services &&
        formCurrentValues?.services?.length > 0 &&
        formCurrentValues?.services.map((value) => {
          return {
            date: value?.expectedDelivery,
            diff: moment().diff(moment(value?.expectedDelivery), "days"),
          };
        });
      minimumNumber = Math.min(...services.map((value) => value.diff));
      furthestDate = services.find((value) => value.diff === minimumNumber);
    }
    form.setFieldsValue({
      expectedDelivery: furthestDate?.date,
    });
  }, [formCurrentValues, form]);

  useEffect(() => {
    if(visible){
      setTimeout(() => {
        autoCompletRef.current.focus()
      }, 100);
    }
  }, [visible]);

  return (
    <Modal 
      open={visible} 
      onCancel={() => {
        form.resetFields()
        setVisible(false)
        setNewColor('')
      }} 
      footer={null}>
      <div className={classNames.wrapper}>
        <h2>Create New Ticket</h2>
        <div>
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            onValuesChange={(changedValues, values) => {
              setFormCurrentValues(values);
            }}
          >
            {/* Remove the existingCustomer toggle and do the neccessary changes on Autocomple input
            such as converting to a controlled component using onSearch methods
            Reason: UAT */}
            {/* <Form.Item
              name="cutomerType"
              rules={[
                {
                  required: true,
                  message:"Customer type is required."
                }
              ]}
            >
              Existing customer{" "}
              <Switch
                checkedChildren="Yes"
                onChange={(event) => setExistingCustomer(event)}
              />
            </Form.Item> */}
            {/* {!existingCustomer ? ( */}
            <div>
              <Form.Item
                name="customerName"
                label="Customer Name"
                rules={[
                  {
                    required: existingCustomer ? false : true,
                    message: "Name is required.",
                  },
                ]}
              >
                <AutoComplete
                  onSearch={() => {
                    setExistingCustomer(false);
                    form.setFieldsValue({
                      mobileNumber: null,
                    });
                  }}
                  ref={autoCompletRef}
                  onSelect={(value) => {
                    if (value) {
                      let user = userList.find((user) => user.id === value);
                      form.setFieldsValue({
                        customerName: user.name,
                        email: user.email,
                        mobileNumber: user.mobileNumber,
                      });
                      setExistingCustomer(true);
                    }
                  }}
                  filterOption={(inputValue, option) =>
                    option.label
                      .toUpperCase()
                      .indexOf(inputValue.toUpperCase()) !== -1
                  }
                  options={usersOptions}
                />
              </Form.Item>
              {/* <Form.Item 
                  name="mobileNumber" 
                  label="Mobile Number"
                  rules={[
                    {
                      required: existingCustomer ? false : true,
                      message:"Mobile is required."
                    }
                  ]}>
                  <Input />
                </Form.Item> */}
            </div>
            {/* ) : null} */}

            <Form.Item
              name="mobileNumber"
              label="Mobile Number"
              rules={[
                {
                  required: existingCustomer ? false : true,
                  message: "Mobile number is required.",
                },
                {
                  validator: (rule, value, callback) => {
                    if (value === Number.isNaN) {
                      callback("Should be a number");
                    }
                    if (!Number(value)) {
                      callback("Only numbers allowed");
                    }
                    if (value.length > 8) {
                      callback("Max 8 digits");
                    }
                    if (value.length < 8) {
                      callback("8 digits required");
                    }
                    callback();
                  },
                },
              ]}
            >
              <Input disabled={existingCustomer} />
            </Form.Item>
            {/* {!existingCustomer && ( */}
            <Form.Item
              name="email"
              label="Email"
              rules={[
                {
                  required: true,
                  message: "Email is required.",
                },
              ]}
            >
              <Input
                disabled={existingCustomer}
                onInput={(e) =>
                  (e.target.value = e.target.value?.toLowerCase())
                }
              />
            </Form.Item>
            {/* )} */}
            <Form.List name="services">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(
                    ({ key, name, fieldKey, ...restField }, index) => {
                      return (
                        <Space
                          key={key}
                          style={{ display: "flex", marginBottom: 8 }}
                          align="start"
                        >
                          <div>
                            <div className={classNames.formListWrapper}>
                              {/* <div className={classNames.nestedFormItemWrapper}> */}
                              <Form.Item
                                {...restField}
                                name={[name, "service"]}
                                fieldKey={[fieldKey, "service"]}
                                label="Job/Service"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please select Job/Service.",
                                  },
                                ]}
                              >
                                <Select
                                  onSelect={(e) =>
                                    generateServicesList(index, e, "service")
                                  }
                                >
                                  {data.services.map((service, index) => {
                                    return (
                                      <Select.Option
                                        value={service}
                                        key={index}
                                      >
                                        {service}
                                      </Select.Option>
                                    );
                                  })}
                                </Select>
                              </Form.Item>
                              <Form.Item
                                {...restField}
                                name={[name, "noOfRims"]}
                                fieldKey={[fieldKey, "noOfRims"]}
                                label="No of Rims"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please select no of rims.",
                                  },
                                ]}
                              >
                                <Select>
                                  {data.noOfRims.map((num, index) => {
                                    return (
                                      <Select.Option value={num} key={index}>
                                        {num}
                                      </Select.Option>
                                    );
                                  })}
                                </Select>
                              </Form.Item>
                              {/* </div>
                          <div className={classNames.nestedFormItemWrapper}> */}
                              <Form.Item
                                {...restField}
                                name={[name, "brandOfRims"]}
                                fieldKey={[fieldKey, "brandOfRims"]}
                                label="Brand of Rims"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please select brand of rims.",
                                  },
                                ]}
                              >
                                {/* <Input /> */}
                                <Select>
                                  {data.rimBrands.map((size, index) => {
                                    return (
                                      <Select.Option value={size} key={index}>
                                        {size}
                                      </Select.Option>
                                    );
                                  })}
                                </Select>
                              </Form.Item>
                              <Form.Item
                                {...restField}
                                name={[name, "sizeOfRims"]}
                                label="Size of Rims"
                                fieldKey={[fieldKey, "sizeOfRims"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please select size of rims.",
                                  },
                                ]}
                              >
                                {/* <Input /> */}
                                <Select>
                                  {data.sizeOfRims.map((size, index) => {
                                    return (
                                      <Select.Option value={size} key={index}>
                                        {size}
                                      </Select.Option>
                                    );
                                  })}
                                </Select>
                              </Form.Item>
                              {[
                                data.services[2],
                                data.services[3],
                                data.services[5],
                              ].some(
                                (value) =>
                                  value ===
                                  formCurrentValues?.services[key]?.service,
                              ) && (
                                <>
                                  <Form.Item
                                    {...restField}
                                    name={[name, "color"]}
                                    fieldKey={[fieldKey, "color"]}
                                    label="Color"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Please select a color.",
                                      },
                                    ]}
                                  >
                                    <Select
                                    defaultValue={newColor}
                                    dropdownRender={(menu) => (
                                      <div>
                                        {menu}
                                        <Divider style={{ margin: "4px 0" }} />
                                        <div
                                          style={{
                                            display: "flex",
                                            flexWrap: "nowrap",
                                            padding: 8,
                                          }}
                                        >
                                          <Input
                                            style={{ flex: "auto" }}
                                            value={newColor}
                                            onChange={(e) =>
                                              setNewColor(e.target.value)
                                            }
                                          />
                                          <a
                                            style={{
                                              flex: "none",
                                              padding: "8px",
                                              display: "block",
                                              cursor: "pointer",
                                              color: "#ffffff",
                                            }}
                                            onClick={() => {
                                              if (newColor) {
                                                setNewColor("");
                                                colorList.push(newColor);
                                              }
                                            }}
                                          >
                                            <PlusOutlined /> Add color
                                          </a>
                                        </div>
                                      </div>
                                    )}
                                      options={colorList.map((item) => ({
                                        label: item,
                                        value: item,
                                      }))}
                                    />
                                  </Form.Item>
                                  <Form.Item
                                    {...restField}
                                    name={[name, "matt"]}
                                    fieldKey={[fieldKey, "matt"]}
                                    label="Paint type"
                                  >
                                <Select allowClear>
                                  {data.paintTypes.map((type, index) => {
                                    return (
                                      <Select.Option  value={type} key={index}>
                                        {type}
                                      </Select.Option>
                                    );
                                  })}
                                </Select>
                                  </Form.Item>
                                </>
                              )}
                              {/* </div>
                          <div className={classNames.nestedFormItemWrapper}> */}
                              <Form.Item
                                {...restField}
                                name={[name, "brandOfTyres"]}
                                fieldKey={[fieldKey, "brandOfTyres"]}
                                label="Brand of Tyres"
                                initialValue={data.tyreBrands[0]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please select brand of tyres.",
                                  },
                                ]}
                              >
                                <Select defaultValue={data.tyreBrands[0]}>
                                  {data.tyreBrands.map((brand, index) => {
                                    return (
                                      <Select.Option value={brand} key={index}>
                                        {brand}
                                      </Select.Option>
                                    );
                                  })}
                                </Select>
                              </Form.Item>
                              <Form.Item
                                {...restField}
                                name={[name, "sizeOfTyres"]}
                                fieldKey={[fieldKey, "sizeOfTyres"]}
                                label="Size of Tyres"
                              >
                                <Input />
                                {/* <Select>
                                {data.sizeOfTyres.map((size, index) => {
                                  return (
                                    <Select.Option value={size} key={index}>
                                      {size}
                                    </Select.Option>
                                  );
                                })}
                              </Select> */}
                              </Form.Item>
                              {/* </div>
                          <div className={classNames.nestedFormItemWrapper}> */}
                              <Form.Item
                                {...restField}
                                name={[name, "noOfCaps"]}
                                fieldKey={[fieldKey, "noOfCaps"]}
                                label="No of Caps"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please select no of caps.",
                                  },
                                ]}
                              >
                                <Select>
                                  {data.noOfCaps.map((brand, index) => {
                                    return (
                                      <Select.Option value={brand} key={index}>
                                        {brand}
                                      </Select.Option>
                                    );
                                  })}
                                </Select>
                              </Form.Item>
                              <Form.Item
                                {...restField}
                                name={[name, "noOfTPMS"]}
                                fieldKey={[fieldKey, "noOfTPMS"]}
                                label="No of TPMS"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please select no of TPMS.",
                                  },
                                ]}
                              >
                                <Select>
                                  {data.noOfTPMS.map((brand, index) => {
                                    return (
                                      <Select.Option value={brand} key={index}>
                                        {brand}
                                      </Select.Option>
                                    );
                                  })}
                                </Select>
                              </Form.Item>
                              <Form.Item
                                {...restField}
                                name={[name, "makeOfTheVehicle"]}
                                fieldKey={[fieldKey, "makeOfTheVehicle"]}
                                label="Make of the Vehicle"
                              >
                                <Select>
                                  {data.carbrands.map((brand, index) => {
                                    return (
                                      <Select.Option value={brand} key={index}>
                                        {brand}
                                      </Select.Option>
                                    );
                                  })}
                                </Select>
                              </Form.Item>
                              <Form.Item
                                {...restField}
                                name={[name, "vehcileNumberPlate"]}
                                fieldKey={[fieldKey, "vehcileNumberPlate"]}
                                label="Vehicle Number Plate"
                              >
                                <Input />
                              </Form.Item>
                              {/* </div>
                          <div className={classNames.nestedFormItemWrapper}> */}
                              <Form.Item
                                {...restField}
                                name={[name, "notes"]}
                                fieldKey={[fieldKey, "notes"]}
                                label="Notes"
                              >
                                <Input.TextArea />
                              </Form.Item>
                              <Form.Item
                                {...restField}
                                name={[name, "image"]}
                                fieldKey={[fieldKey, "image"]}
                                label="Image Atachement"
                              >
                                <Upload
                                  // disabled={true}
                                  listType="picture"
                                  maxCount={3}
                                  multiple
                                  beforeUpload
                                  // onChange={(state) => setFileList(state.fileList)}
                                >
                                  <Button icon={<UploadOutlined />}>
                                    Upload
                                  </Button>
                                </Upload>
                              </Form.Item>
                              <Form.Item
                                {...restField}
                                name={[name, "dateOfreceipt"]}
                                fieldKey={[fieldKey, "dateOfreceipt"]}
                                label="Date Received"
                              >
                                <DatePicker
                                  disabled={true}
                                  defaultValue={moment()}
                                />
                              </Form.Item>
                              <Form.Item
                                {...restField}
                                name={[name, "expectedDelivery"]}
                                fieldKey={[fieldKey, "expectedDelivery"]}
                                label="Expected Delivery"
                              >
                                <DatePicker disabledDate={disabledDate} />
                              </Form.Item>
                              {/* <Form.Item
                              {...restField}
                              name={[name, "actualDelivery"]}
                              fieldKey={[fieldKey, "actualDelivery"]}
                              label="Actual Delivery"
                            >
                              <DatePicker />
                            </Form.Item> */}
                              {/* </div> */}
                            </div>{" "}
                            <div>
                              {/* <Button type="primary" htmlType="button" >Add Job to ticket</Button> */}
                              Remove job{" "}
                              <MinusCircleOutlined
                                onClick={() => remove(name)}
                              />
                            </div>
                          </div>
                        </Space>
                      );
                    },
                  )}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add New Job
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
            <Form.Item name="dateOfreceipt" label="Date Received">
              <DatePicker disabled={true} defaultValue={moment()} />
            </Form.Item>
            <Form.Item name="expectedDelivery" label="Expected Delivery">
              <DatePicker disabledDate={disabledDate} disabled />
            </Form.Item>
            {/* <Form.Item name="actualDelivery" label="Actual Delivery">
                    <DatePicker />
                  </Form.Item> */}
            <Form.Item>
              <Button
                loading={fetching || uploading}
                type="primary"
                htmlType="submit"
              >
                {" "}
                Submit{" "}
              </Button>
            </Form.Item>
            <Form.Item>
              <Button type="primary" onClick={() => form.resetFields()}>
                {" "}
                Clear all{" "}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </Modal>
  );
}
