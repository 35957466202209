import api from "../../../api";
import { types } from "../../types";

export default function listTicketsByCompanyIdAction(values) {
  return async function listTicketsByCompanyIdActionThunk(dispatch) {
    dispatch({ type: types.ticket.listTicketsByCompanyId.started });

    try {
      const data = await api.ticket.list(values);
      dispatch({
        type: types.ticket.listTicketsByCompanyId.success,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: types.ticket.listTicketsByCompanyId.failed,
        payload: error,
      });
    }
  };
}
