import api from "../../../api";
import { types } from "../../types";

export default function deleteJobAction(values) {
  return async function deleteJobActionThunk(dispatch) {
    dispatch({ type: types.job.delete.started, payload: false });

    try {
      const data = await api.job.delete(values);
      dispatch({ type: types.job.delete.success, payload: data });
    } catch (error) {
      dispatch({ type: types.job.delete.failed, payload: error });
    }
  };
}
