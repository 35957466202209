import classNames from "./dashboard.module.scss";
import { Button, Input, message, Popover, Table } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFetchUser } from "../../hooks/useFetchUser";
import UpdateCustomerModal from "./UpdateCustomer/UpdateCustomerModal";
import { ac } from "../../store/actionCreators";
import CreateCustomerModal from "./CreateCustomer/CreateCustomerModal";
import api from "../../api";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

export default function CustomerManagement() {
  const [keyword, setKeyword] = useState();
  const [record, setRecord] = useState(null);
  const [visible, setVisible] = useState();
  const [createCustomerModalVisibility, setCreateCustomerModalVisibility] =
    useState();
  let companyId = "oslofelgretting.no";
  const queryClient = useQueryClient();

  const { Search } = Input;
  const currentUser = useFetchUser();

  const dispatch = useDispatch();
  const { data: customerListData, isLoading: fetchingUsersByCompanyId } =
    useQuery(["getCustomersByCompanyId"], () => api.user.getUsersByCompany(), {
      enabled: false,
    });

  const { data: ticketListData } = useQuery(
    ["getTicketsByCompanyId"],
    () => api.ticket.list(),
    {
      enabled: false,
    },
  );

  const userList =
    customerListData?.data?.usersByCompanyAndCreatedAt?.items || [];

  /**
   * Remove redux selector for list company customers
   * Moved to react query.
   * Remove below code at the revamp
   */
  // const userList = useSelector(function ({ getUsersByCompanyId }) {
  //   return getUsersByCompanyId.data
  //     ? getUsersByCompanyId.data
  //       ? getUsersByCompanyId.data.getUsersByCompanyId
  //       : []
  //     : [];
  // });

  // const fetchingUsersByCompanyId = useSelector(function ({
  //   getUsersByCompanyId,
  // }) {
  //   return getUsersByCompanyId.fetching;
  // });

  // const createUserSuccess = useSelector(function ({ createUser }) {
  //   return createUser.success;
  // });

  // const createUserError = useSelector(function ({ createUser }) {
  //   return createUser.error;
  // });

  const updateUserSuccess = useSelector(function ({ updateUser }) {
    return updateUser.success;
  });

  const updateUserError = useSelector(function ({ updateUser }) {
    return updateUser.error;
  });

  const deleteUserSuccess = useSelector(function ({ deleteUser }) {
    return deleteUser.success;
  });

  const deleteUserError = useSelector(function ({ deleteUser }) {
    return deleteUser.error;
  });

  // const fetching = useSelector(function ({ deleteUser }) {
  //   return deleteUser.fetching;
  // });

  const onClickView = (text, record) => {
    setRecord(record);
    setVisible(true);
  };

  const onClickDelete = (text, record) => {
    dispatch(ac.deleteUser({ id: record.id }));
    setTimeout(dispatch(ac.getUsersByCompanyId()), 20000);
  };
  const dataSource =
    userList && userList.length > 0
      ? userList
          .sort(
            (a, b) =>
              new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
          )
          .map((item, index) => {
            return {
              key: index,
              id: item.id,
              customerName: item.name,
              customerEmail: item.email,
              customerMobileNumber: item.mobileNumber,
            };
          })
      : [];

  const columns = [
    {
      title: "Name",
      dataIndex: "customerName",
      key: "customerName",
      sorter: (a, b) =>
        a.customerName && a.customerName.localeCompare(b.customerName),

      //   fixed: "left",
      // render: (text, record) => {
      //   return <div>{text.split("+")[1]}</div>;
      // },
      // sorter: (a, b) => a.key - b.key
    },
    {
      title: "Email",
      dataIndex: "customerEmail",
      key: "customerEmail",
      responsive: ["lg"],
    },
    {
      title: "Mobile Number",
      dataIndex: "customerMobileNumber",
      key: "customerMobileNumber",
      responsive: ["lg"],
    },
    {
      title: "No Of Tickets",
      dataIndex: "noOfTickets",
      key: "noOfTickets",
      responsive: ["lg"],
      render: (text, record) => {
        let ticketsArray = ticketListData
          ? ticketListData.data?.listTicketsByCompanyAndCreatedAt?.items
          : [];
        let ticketsPerCustomer =
          ticketsArray.filter((item) => item.userId === record.id) || [];
        return ticketsPerCustomer.length;
      },
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      fixed: "right",
      render: (text, record) => {
        return (
          <div>
            <Button type="primary" onClick={() => onClickView(text, record)}>
              View/Update
            </Button>
            {currentUser?.type === "admin" && (
              <Popover
                title="Are you sure?"
                content={
                  <Button
                    type="primary"
                    // loading={fetching}
                    onClick={() => onClickDelete(text, record)}
                  >
                    Yes
                  </Button>
                }
              >
                <Button type="primary">Delete</Button>
              </Popover>
            )}
          </div>
        );
      },
    },
  ];

  const onFinishUpdateCustomer = (data) => {
    let input = {
      id: record.id,
      name: data.name ? data.name : record.customerName,
      email: data.email ? data.email : record.customerEmail,
      mobileNumber: data.mobileNumber
        ? data.mobileNumber
        : record.customerMobileNumber,
    };
    dispatch(ac.updateUser(input));
    setRecord(null);
    setTimeout(dispatch(ac.getUsersByCompanyId()), 20000);
  };

  function createCustomerFn(values) {
    let userData = {
      input: {
        // id: values.email,
        name: values.name,
        email: values.email,
        mobileNumber: values.mobileNumber,
        companyId: companyId,
        type: "customer",
        keywords: [
          values.name?.toLowerCase(),
          values.email?.toLowerCase(),
          values.mobileNumber,
        ],
      },
    };
    return api.user.create(userData);
  }
  const createCustomerMn = useMutation((event) => createCustomerFn(event));

  const onFinishCreateCustomer = (values) => {
    createCustomerMn.mutate(values, {
      onSuccess: (data) => {
        queryClient.invalidateQueries("getCustomersByCompanyId");
        setCreateCustomerModalVisibility(false);
        message.success("User created successfully!");
        userList?.push(data.data.data?.createUser);
      },
      onError: (error) => {
        message.error("Error");
      },
    });
    // let userData = {
    //   input: {
    //     // id: values.email,
    //     name: values.name,
    //     email: values.email,
    //     mobileNumber: values.mobileNumber,
    //     companyId: companyId,
    //     type: "customer",
    //     keywords: [
    //       values.name?.toLowerCase(),
    //       values.email?.toLowerCase(),
    //       values.mobileNumber,
    //     ],
    //   },
    // };
    // dispatch(ac.createUser(userData));
  };

  useEffect(() => {
    if (updateUserSuccess) {
      message.success("User updated successfully!");
      queryClient.invalidateQueries("getCustomersByCompanyId");
    }
    if (updateUserError) {
      message.error("Error!");
      setVisible(true);
    }
  }, [queryClient, updateUserError, updateUserSuccess]);

  useEffect(() => {
    if (deleteUserSuccess) {
      queryClient.invalidateQueries("getCustomersByCompanyId");
      message.success("User deleted successfully!");
    }
    if (deleteUserError) {
      message.error("Error!");
    }
  }, [deleteUserError, deleteUserSuccess, queryClient]);

  // useEffect(() => {
  //   if (createUserSuccess) {
  //     queryClient.invalidateQueries("getCustomersByCompanyId");
  //     message.success("User created successfully!");
  //   }
  //   if (createUserError) {
  //     message.error("Error!");
  //   }
  // }, [dispatch, createUserError, createUserSuccess, queryClient]);

  return (
    <div>
      <h2>Customer Management</h2>
      <div className={classNames.crmHeader}>
        {/* <Input.Group compact> */}
        {/* <Form.Item name="category">
            <Select defaultValue="ticketDisplayId" bordered>
              <Option value="userId">Email</Option>
              <Option value="ticketDisplayId">Ticket Id</Option>
              <Option value="mobileNumber">Mobile Number</Option>
            </Select>
          </Form.Item> */}
        {/* <Form.Item name="input"> */}
        <Search
          allowClear
          onChange={(e) => setKeyword(e.target.value)}
          placeholder="Name, Email or Mobile"
        />
        <Button
          style={{ margin: "5px" }}
          type="primary"
          onClick={() => setCreateCustomerModalVisibility(true)}
        >
          Create New Customer
        </Button>
        <Button
          style={{ margin: "5px" }}
          type="primary"
          onClick={() => dispatch(ac.getUsersByCompanyId())}
        >
          Refresh
        </Button>
        {/* </Form.Item> */}
        {/* </Input.Group> */}
        {/* <Form.Item>
          <Button type="primary" htmlType="submit">
            Search
          </Button>
        </Form.Item> */}
      </div>

      <Table
        className={classNames.tableWrapper}
        dataSource={
          keyword
            ? dataSource &&
              dataSource.length > 0 &&
              dataSource.filter((obj) => {
                return Object.values(obj)
                  .toString()
                  .toLowerCase()
                  .includes(keyword.toLowerCase());
              })
            : dataSource
        }
        columns={columns}
        scroll={{ x: 150 }}
        sticky
        loading={fetchingUsersByCompanyId}
      />
      <CreateCustomerModal
        dataSource={dataSource}
        visible={createCustomerModalVisibility}
        setVisible={setCreateCustomerModalVisibility}
        onFinish={onFinishCreateCustomer}
      />
      <UpdateCustomerModal
        dataSource={dataSource}
        record={record}
        setVisible={setVisible}
        visible={visible}
        setRecord={setRecord}
        onFinish={onFinishUpdateCustomer}
      />
    </div>
  );
}
