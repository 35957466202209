// static data for the application is not using anymore. It is replaced with the data from the API.
// refer src\utils\transformMetadata.js for the updated implementation.
export const data = {
  status: [
    "All",
    "In-Progress",
    // "Complete",
    "Completed",
    "Completed & Delivered",
    // Remove "Delayed" option as requested by the client.
    // Uncomment below line (10) if client need that back
    // "Delayed",
  ],
  noOfCaps: ["0", "1", "2", "3", "4", "5"],
  noOfTPMS: ["0", "1", "2", "3", "4", "5"],
  sizeOfTyres: ["1", "2", "3", "4"],
  sizeOfRims: [
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
  ],
  noOfRims: [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
  ],
  carbrands: [
    "Alfa Romeo",
    "Audi",
    "Bentley",
    "BMW",
    "Caterham",
    "Chevrolet",
    "Dodge",
    "Ferrari",
    "Fiat",
    "Ford",
    "Honda",
    "Hyundai",
    "Jaguar",
    "Jeep",
    "Kia",
    "Land Rover",
    "Lotus",
    "Maserati",
    "Mercedes",
    "Mercedes AMG",
    "MG",
    "Mini",
    "Mitsubishi",
    "Nissan",
    "Opel",
    "Other",
    "Polestar",
    "Porsche",
    "Rolls Royce",
    "Seat",
    "Skoda",
    "Taiwan",
    "Tesla",
    "Toyota",
    "Triumph",
    "TVR",
    "VW",
  ],
  tyreBrands: [
    "No Tyres",
    "Achilles",
    "BF Goodridge",
    "Bridgestone",
    "China crap",
    "Continental",
    "Cooper",
    "Dunlop",
    "Falken",
    "General",
    "Goodyear",
    "Hankook",
    "Kumho",
    "Landfail",
    "Linglong",
    "Maxxis",
    "Michelin",
    "Minerva",
    "Nankang",
    "Nexen",
    "Nokian",
    "Nordman",
    "Other",
    "Pirelli",
    "Toyo",
    "Uniroyal",
    "Vredestein",
    "Yokohama",
  ],
  services: [
    "Straighting",
    "Welding",
    "Wetpaint",
    "Refurbish",
    "Quickfix",
    "Wetpaint + DC",
    "New tyres",
    "Web tyres",
    "Used tyres",
    "Hotel",
    "Custom",
    "Make Sensors",
  ],
  servicesWithDueDates: [
    { name: "Straighting", days: 5 },
    { name: "Welding", days: 5 },
    { name: "Wetpaint", days: 5 },
    { name: "Refurbish", days: 21 },
    { name: "Quickfix", days: 5 },
    { name: "Wetpaint + DC", days: 5 },
    { name: "New tyres", days: 1 },
    { name: "Web tyres", days: 1 },
    { name: "Used tyres", days: 1 },
    { name: "Hotel", days: 1 },
    { name: "Custom", days: 1 },
    { name: "Make Sensors", days: 1 },
  ],
  rimBrands: [
    "AEZ",
    "Alfa Romeo",
    "ASA",
    "ATS",
    "Audi",
    "Azev",
    "BBS",
    "Bentley",
    "Beyern (TSW)",
    "BMW",
    "Bola",
    "Borbet",
    "Brock",
    "Caterham",
    "Cheap rim",
    "Chevrolet",
    "China / Taiwan",
    "Dodge",
    "Ferrari",
    "Fiat",
    "Ford",
    "Honda",
    "Hyundai",
    "Image wheels",
    "Italy",
    "Jaguar",
    "Jeep",
    "Kia",
    "Lamborghini",
    "Land Rover",
    "Lexus",
    "Lotus",
    "MAK",
    "Mandrus (TSW)",
    "Maserati",
    "Mercedes",
    "Mercedes AMG",
    "MG",
    "Mini",
    "Mitsubishi",
    "Motorcycle parts",
    "Motorcycle wheel",
    "Nio",
    "Nissan",
    "Opel",
    "Other",
    "Polestar",
    "Porsche",
    "RH",
    "Rolls Royce",
    "Seat",
    "Skoda",
    "Taiwan",
    "Tesla",
    "Toyota",
    "Triumph",
    "TSW",
    "TVR",
    "Victor (TSW)",
    "VW",
    "Volvo",
    "Work",
  ],
  colors: [
    "NE Silver",
    "Sparkle sliver",
    "Super Silver",
    "Ferric",
    "Gunmetal",
    "Black",
    "White",
    "Red",
    "Dark red",
    "Yellow",
    "Custom",
  ],
  paintTypes: ["Matt"],
  metaDataTypes: [
    {
      id: "noOfCaps",
      fieldName: "No of Caps",
      subFields: [],
    },
    {
      id: "noOfTPMS",
      fieldName: "No of TPMS",
      subFields: [],
    },
    {
      id: "sizeOfTyres",
      fieldName: "Size of Tyres",
      subFields: [],
    },
    {
      id: "sizeOfRims",
      fieldName: "Size of Rims",
      subFields: [],
    },
    {
      id: "noOfRims",
      fieldName: "No of Rims",
      subFields: [],
    },
    {
      id: "carbrands",
      fieldName: "Car Brands",
      subFields: [],
    },
    {
      id: "tyreBrands",
      fieldName: "Tyre Brands",
      subFields: [],
    },
    {
      id: "services",
      fieldName: "Services",
      subFields: [
        {
          id: "servicesWithDueDates",
          fieldName: "Delivery Estimate (Days)",
        },
      ],
    },
    {
      id: "rimBrands",
      fieldName: "Rim Brands",
      subFields: [],
    },
    {
      id: "colors",
      fieldName: "Colors",
      subFields: [],
    },
    {
      id: "paintTypes",
      fieldName: "Paint Types",
      subFields: [],
    },
  ],
};
