import api from "../../../api";
import { types } from "../../types";

export default function getUsersByCompanyIdAction() {
  return async function getUsersByCompanyIdThunk(dispatch) {
    dispatch({ type: types.user.getUsersByComapnyId.started, payload: false });

    try {
      const data = await api.user.getUsersByCompany();
      dispatch({ type: types.user.getUsersByComapnyId.success, payload: data });
    } catch (error) {
      dispatch({ type: types.user.getUsersByComapnyId.failed, payload: error });
    }
    // dispatch({ type: types.user.getUsersByComapnyId.reset});
  };
}
