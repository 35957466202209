import { Button, Form, Input, Modal } from "antd";
import { useSelector } from "react-redux";
import classNames from "./createCustomer.module.scss";
import { useEffect, useRef } from "react";

export default function CreateCustomerModal({
  visible,
  setVisible,
  onFinish,
  dataSource,
}) {
  const [form] = Form.useForm();
  const customerInputRef = useRef(null);

  const createUserFetching = useSelector(function ({ createUser }) {
    return createUser.fetching;
  });

  useEffect(() => {
    if(visible){
      setTimeout(() => {
        customerInputRef.current.focus()
      }, 100);
    }
  }, [visible]);
  return (
    <Modal open={visible} onCancel={() => setVisible(false)} footer={null}>
      <div className={classNames.wrapper}>
        <h2>Create New Customer</h2>
        <div>
          <Form form={form} onFinish={onFinish}>
            <Form.Item
              name="name"
              label="Name"
              rules={[
                {
                  required: true,
                  message: "Name is required.",
                },
                {
                  validator: (rule, value, callback) => {
                    var obj =
                      Array.isArray(dataSource) && dataSource.length > 0
                        ? dataSource.find((val) => val.customerName === value)
                        : null;
                    if (obj) {
                      callback(`${value} exists`);
                    }
                    callback();
                  },
                },
              ]}
            >
              <Input ref={customerInputRef}/>
            </Form.Item>
            <Form.Item
              name="email"
              label="Email"
              rules={[
                {
                  required: true,
                  type: "email",
                  message: "Email is required.",
                },
                {
                  validator: (rule, value, callback) => {
                    var obj =
                      Array.isArray(dataSource) && dataSource.length > 0
                        ? dataSource.find((val) => val.customerEmail === value)
                        : null;
                    if (obj) {
                      callback(`${value} exists`);
                    }
                    callback();
                  },
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="mobileNumber"
              label="Mobile Number"
              rules={[
                {
                  required: true,
                  message: "Mobile number is required.",
                },
                {
                  validator: (rule, value, callback) => {
                    var obj =
                      Array.isArray(dataSource) && dataSource.length > 0
                        ? dataSource.find(
                            (val) => val.customerMobileNumber === value,
                          )
                        : null;
                    if (obj) {
                      callback(`${value} exists`);
                    }
                    if (value === Number.isNaN) {
                      callback("Should be a number");
                    }
                    if (!Number(value)) {
                      callback("Only numbers allowed");
                    }
                    if (value.length > 8) {
                      callback("Max 8 digits");
                    }
                    if (value.length < 8) {
                      callback("8 digits required");
                    }
                    callback();
                  },
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item>
              <Button
                loading={createUserFetching}
                type="primary"
                htmlType="submit"
              >
                Create
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </Modal>
  );
}
