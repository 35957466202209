import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { ac } from "../../store/actionCreators";
import { FaSignOutAlt } from "react-icons/fa";

const SignOut = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const user = useSelector(function ({ signIn }) {
    return signIn.data;
  });

  const signedOut = useSelector(function ({ signOut }) {
    return signOut.success;
  });

  function onClick() {
    dispatch(ac.signOut(user.username));
  }

  useEffect(() => {
    if (signedOut) {
      history.push("/");
      window.location.reload();
    }
  }, [history, signedOut]);

  return (
    <div>
      {/* <Button onClick={onClick} type="primary" htmlType="submit"> */}
      <FaSignOutAlt
        size="30px"
        onClick={onClick}
        type="primary"
        // htmlType="submit"
      />
      {/* </Button> */}
    </div>
  );
};

export default SignOut;
