import api from "../../../api";
import { types } from "../../types";

export default function updateTicketAction(values, array) {
  return async function updateTciketActionThunk(dispatch) {
    dispatch({ type: types.ticket.update.started, payload: false });
    try {
      const data = await api.ticket.update(values, array);
      dispatch({ type: types.ticket.update.success, payload: data });
    } catch (error) {
      dispatch({ type: types.ticket.update.failed, payload: error });
    }
    dispatch({ type: types.ticket.update.reset });
  };
}
