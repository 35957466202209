import api from "../../../api";
import { types } from "../../types";

export default function createJobAction(values, array) {
  return async function createJobActionThunk(dispatch) {
    dispatch({ type: types.job.create.started, payload: false });

    try {
      const data = await api.job.create(values, array);
      dispatch({ type: types.job.create.success, payload: data });
    } catch (error) {
      dispatch({ type: types.job.create.failed, payload: error });
    }
  };
}
