import { API, graphqlOperation } from "@aws-amplify/api";
import { deleteUser } from "../../graphql/mutations";

export default async function deleteUserByIdApi(input) {
  const variables = {
    input,
  };

  return await API.graphql(graphqlOperation(deleteUser, variables));
}
