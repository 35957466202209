import api from "../../../api";
import { types } from "../../types";

export default function signInAction(values) {
  return async function signInActionThunk(dispatch) {
    dispatch({ type: types.auth.signIn.started, payload: false });
    try {
      const data = await api.auth.signIn(values);
      dispatch({ type: types.auth.signIn.success, payload: data });
    } catch (error) {
      dispatch({ type: types.auth.signIn.failed, payload: error.name });
    }
  };
}
