import { v4 as uuidv4 } from "uuid";
import Storage from "@aws-amplify/storage";
import { data } from "../../data/data";
import { API, graphqlOperation } from "@aws-amplify/api";
import { createJob } from "../../graphql/mutations";

export default async function createJobApi(input, fileList) {
  let array = [];

  for (let index = 0; index < fileList.length; index++) {
    const image = fileList[index];
    let id = `${image.uid}-${uuidv4()}-${index}`;
    await Storage.put(id, image, {
      level: "public",
      contentType: image.type,
    })
      .then((data) => array.push(data.key))
      .catch((error) => console.log(index + ". Image Upload Error: ", error));
  }

  let companyId = "oslofelgretting.no";

  let index = 1; //# of the job object
  const jobDisplayIdGen = () => {
    if (input && input.service === "Refurbish") {
      return `${index}R`;
    } else return `${index}`;
  };
  let jobData = {
    input: {
      jobDisplayId: jobDisplayIdGen(),
      userId: input.email,
      companyId: companyId,
      jobName: input.service,
      numberOfRims: input.noOfRims,
      brandOfRims: input.brandOfRims,
      sizeOfRims: input.sizeOfRims,
      brandOfTyre: input.brandOfTyres,
      sizeOfTyre: input.sizeOfTyres,
      makeOfVehicle: input.makeOfTheVehicle,
      vehicleNumberPlate: input.vehcileNumberPlate,
      notes: input.notes,
      imagesUrl: JSON.stringify(array),
      status: data.status[0],
      dateOfReceipt: new Date().toISOString(),
      expectedDelivery: new Date().toISOString(),
      actualDelivery: new Date().toISOString(),
    },
  };

  return await API.graphql(graphqlOperation(createJob, { ...jobData }));
}
