import api from "../../../api";
import { types } from "../../types";

export default function forgotPasswordSubmitAction(values) {
  return async function forgotPasswordSubmitActionThunk(dispatch) {
    dispatch({ type: types.auth.forgotPassword.started, payload: false });
    try {
      const data = await api.auth.forgotPasswordSubmit(values);
      dispatch({
        type: types.auth.forgotPasswordSubmit.success,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: types.auth.forgotPasswordSubmit.failed,
        payload: error,
      });
    }
    dispatch({ type: types.auth.forgotPasswordSubmit.reset });
  };
}
