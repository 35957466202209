import api from "../../../api";
import { types } from "../../types";

export default function createTicketAction({
  values,
  userId,
  customerName,
  customerEmail,
  customerMobileNumber,
}) {
  return async function createTciketActionThunk(dispatch) {
    dispatch({ type: types.ticket.create.started, payload: false });
    try {
      const data = await api.ticket.create(
        values,
        userId,
        customerName,
        customerEmail,
        customerMobileNumber,
      );
      dispatch({ type: types.ticket.create.success, payload: data });
    } catch (error) {
      dispatch({ type: types.ticket.create.failed, payload: error });
    }
    dispatch({ type: types.ticket.create.reset });
  };
}
