import { Form, Input, Button, message, Popover, Table } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFetchUser } from "../../hooks/useFetchUser";
import { ac } from "../../store/actionCreators";
import UpdateAdminUserModal from "../../common/components/DomainComponents/UpdateAdminUser/UpdateAdminUserModal";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "../../api";

export default function UserManagement({ adminUserList }) {
  const queryClient = useQueryClient();
  const [record, setRecord] = useState(null);
  const [visible, setVisible] = useState();

  const [addAdminUserForm] = Form.useForm();
  const currentUser = useFetchUser();

  const dispatch = useDispatch();

  const inviteMemberFetching = useSelector(function ({ inviteMember }) {
    return inviteMember.fetching;
  });
  const inviteMemberSuccess = useSelector(function ({ inviteMember }) {
    return inviteMember.success;
  });
  const inviteMemberFail = useSelector(function ({ inviteMember }) {
    return inviteMember.error;
  });
  const success = useSelector(function ({ deleteUser }) {
    return deleteUser.success;
  });

  const error = useSelector(function ({ deleteTicket }) {
    return deleteTicket.error;
  });

  const addAdminUserOnFinish = (values) => {
    dispatch(
      ac.inviteMember({
        name: values.name,
        email: values.email,
        companyId: "oslofelgretting.no",
      }),
    );
  };
  useEffect(() => {
    if (success) {
      message.success("User deleted successfully");
    }
    if (error) {
      message.warning("Error");
    }
  });
  useEffect(() => {
    if (inviteMemberSuccess) {
      message.success("Invite sent successfully!");
    }
    if (inviteMemberFail) {
      message.warning("Error");
    }
  });

  const onClickView = (text, record) => {
    setRecord(record);
    setVisible(true);
  };

  const resetPasswordMn = useMutation((event) => resetPasswordFn(event));
  const resetPasswordFn = (event) => {
    return api.auth.managerResetPassword(event);
  };
  const onClickResetPassword = (text, record) => {
    resetPasswordMn.mutate(
      { email: record.adminUserEmail },
      {
        onSuccess: async () => {
          message.success(
            "Password reset email sent to " + record.adminUserEmail,
          );
        },
        onError: () => {
          message.error("Error!");
        },
      },
    );
  };

  const dataSource = useMemo(() => {
    return adminUserList && adminUserList.length > 0
      ? adminUserList
          .sort(
            (a, b) =>
              new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
          )
          .map((item, index) => {
            return {
              key: index,
              id: item.id,
              adminUserName: item.name,
              adminUserEmail: item.email,
              adminUserMobileNumber: item.mobileNumber,
            };
          })
      : [];
  }, [adminUserList]);

  const columns = useMemo(() => {
    return [
      {
        title: "Name",
        dataIndex: "adminUserName",
        key: "adminUserName",
        sorter: (a, b) =>
          a.adminUserName && a.adminUserName.localeCompare(b.adminUserName),
        render: (text, record) => {
          return (
            <p>
              {text} {record.id === currentUser?.id && "(You)"}
            </p>
          );
        },
      },
      {
        title: "Email",
        dataIndex: "adminUserEmail",
        key: "adminUserEmail",
        responsive: ["lg"],
      },
      {
        title: "Mobile Number",
        dataIndex: "adminUserMobileNumber",
        key: "adminUserMobileNumber",
        responsive: ["lg"],
      },
      {
        title: "",
        dataIndex: "action",
        key: "action",
        fixed: "right",
        render: (text, record) => {
          return (
            <div>
              <Button
                type="primary"
                onClick={() => onClickResetPassword(text, record)}
              >
                Reset Password
              </Button>
              <Button type="primary" onClick={() => onClickView(text, record)}>
                View/Update
              </Button>
              {record.id !== currentUser?.id &&
                currentUser?.type === "admin" && (
                  <Popover
                    title="Are you sure? This action cannot be undone."
                    content={
                      <Button
                        type="primary"
                        onClick={() => onClickDelete(text, record)}
                      >
                        Yes
                      </Button>
                    }
                  >
                    <Button type="primary">Delete</Button>
                  </Popover>
                )}
            </div>
          );
        },
      },
    ];
  }, [currentUser]);

  // write this with react query
  const deleteManagerUserFn = (event) => {
    return api.auth.deleteManagerUserApi(event);
  };
  const deleteManagerUserMn = useMutation((event) =>
    deleteManagerUserFn(event),
  );
  const onClickDelete = (text, record) => {
    deleteManagerUserMn.mutate(
      { email: record.adminUserEmail, id: record.id },
      {
        onSuccess: async () => {
          message.success("Admin User deleted successfully!");
          queryClient.invalidateQueries("getAdminUsers");
          setRecord(null);
        },
        onError: () => {
          message.error("Error!");
        },
      },
    );
  };

  const updateAdminUserMn = useMutation((event) => updateAdminUserFn(event));

  const updateAdminUserFn = (event) => {
    let input = {
      id: record.id,
      name: event.name ? event.name : record.adminUserName,
      email: event.email ? event.email : record.adminUserEmail,
      mobileNumber: event.mobileNumber
        ? event.mobileNumber
        : record.adminUserMobileNumber,
    };
    return api.auth.updateAdminUserApi(input);
  };
  const onFinishUpdateCustomer = (data) => {
    updateAdminUserMn.mutate(data, {
      onSuccess: async () => {
        message.success("Admin User updated successfully!");
        queryClient.invalidateQueries("getAdminUsers");
        setVisible(false);
      },
      onError: () => {
        message.error("Error!");
      },
    });
  };

  return (
    <div>
      <h2>User management</h2>
      {currentUser?.type === "admin" && (
        <div>
          <h4>Add manager user</h4>
          <Form
            form={addAdminUserForm}
            onFinish={addAdminUserOnFinish}
            layout="inline"
          >
            <Form.Item name="name" label="Name">
              <Input />
            </Form.Item>
            <Form.Item name="email" label="Email">
              <Input />
            </Form.Item>
            <Form.Item>
              <Button
                loading={inviteMemberFetching}
                type="primary"
                htmlType="submit"
              >
                {" "}
                Submit{" "}
              </Button>
            </Form.Item>
          </Form>
        </div>
      )}
      <Table dataSource={dataSource} columns={columns} />
      <UpdateAdminUserModal
        dataSource={dataSource}
        record={record}
        setVisible={setVisible}
        visible={visible}
        setRecord={setRecord}
        onFinish={onFinishUpdateCustomer}
      />{" "}
    </div>
  );
}
