import api from "../../../api";
import { types } from "../../types";

export default function getUserAction(values) {
  return async function getUserThunk(dispatch) {
    dispatch({ type: types.user.get.started, payload: false });

    try {
      const data = await api.user.get(values);
      dispatch({ type: types.user.get.success, payload: data });
    } catch (error) {
      dispatch({ type: types.user.get.failed, payload: error });
    }
    // dispatch({ type: types.user.get.reset });
  };
}
