import api from "../../../api";
import { types } from "../../types";

export default function updateUserAction(values) {
  return async function updateUserActionThunk(dispatch) {
    dispatch({ type: types.user.update.started, payload: false });
    try {
      const data = await api.user.update(values);
      dispatch({ type: types.user.update.success, payload: data });
    } catch (error) {
      dispatch({ type: types.user.update.failed, payload: error });
    }
    dispatch({ type: types.user.update.reset });
  };
}
