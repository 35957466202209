import api from "../../../api";
import { types } from "../../types";

export default function listAdminUsersIdAction(values) {
  return async function listAdminUsersIdActionThunk(dispatch) {
    dispatch({ type: types.auth.listAdminUsers.started });

    try {
      const data = await api.auth.listAdminUsers();
      dispatch({
        type: types.auth.listAdminUsers.success,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: types.auth.listAdminUsers.failed,
        payload: error,
      });
    }
  };
}
