import React, { useEffect, useState, useMemo, useCallback } from "react";
import Storage from "@aws-amplify/storage";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { DatePicker, Modal, Image, Spin, Upload } from "antd";
import { Button, Form, Input, Select, message } from "antd";
import classNames from "./updateTicketModal.module.scss";
import { ac } from "../../../store/actionCreators";
import { transformMetadata } from "../../../utils/transformMetadata";
import { MdRefresh } from "react-icons/md";
import { useFetchUser } from "../../../hooks/useFetchUser";
import { UploadOutlined } from "@ant-design/icons";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import api from "../../../api";
export default function UpdateTicketModal({
  visible,
  recordId,
  setVisible,
  metadata,
}) {
  const data = transformMetadata(metadata);
  const [form] = Form.useForm();
  // const [ticketStatus, setTicketStatus] = useState(false);
  const [imageElements, setImageElements] = useState([]);
  const [changedValues, setChangedValues] = useState([]);
  const [comments, setComments] = useState([]);
  const [commentImageFile, setCommentImageFile] = useState({});
  const dispatch = useDispatch();
  const currentUser = useFetchUser();

  const queryClient = useQueryClient();

  const { data: ticketData, isLoading: ticketFetching } = useQuery(
    ["getTicketByRecordId", recordId],
    () => api.ticket.get(recordId),
    {
      enabled: !!recordId,
    },
  );

  const ticket = useMemo(
    () => (recordId ? ticketData?.data?.getTicket : {}),
    [ticketData, recordId],
  );

  const jobs =
    ticket?.jobsArray &&
    ticket?.jobsArray?.items &&
    ticket?.jobsArray?.items.length > 0
      ? ticket?.jobsArray?.items
      : [];

  const jobsFetching = useSelector(function ({ jobsBatchGet }) {
    return jobsBatchGet.fetching;
  });

  // const success = useSelector(function ({ updateTicket }) {
  //   return updateTicket.success;
  // });

  const updateJobSuccess = useSelector(function ({ updateJob }) {
    return updateJob.success;
  });

  // const error = useSelector(function ({ updateTicket }) {
  //   return updateTicket.error;
  // });

  // const updateJobError = useSelector(function ({ updateJob }) {
  //   return updateJob.error;
  // });

  const fetching = useSelector(function ({ updateTicket }) {
    return updateTicket.fetching;
  });

  const updateJobfetching = useSelector(function ({ updateJob }) {
    return updateJob.fetching;
  });

  let overallProgressArray = [];

  let commentsObj = {
    array: comments,
  };

  const updateTicketFn = (values) => {
    if (
      overallProgressArray &&
      overallProgressArray.length > 0 &&
      overallProgressArray.every(
        (item, index) => item === "Completed & Delivered",
      )
    ) {
      let input = {
        id: recordId,
        expectedDelivery: values.expectedDelivelry,
        actualDelivery: values.actualDelivery,
        notes: JSON.stringify(commentsObj),
        ticketStatus: "Completed & Delivered",
      };
      return api.ticket.update(input);
      // dispatch(ac.updateTicket(input));
    } else {
      let input = {
        id: recordId,
        expectedDelivery: values.expectedDelivery,
        actualDelivery: values.actualDelivery,
        notes: JSON.stringify(commentsObj),
        ticketStatus: values.ticketStatus,
      };
      return api.ticket.update(input);
      // dispatch(ac.updateTicket(input));
    }
  };

  const updateTicketMn = useMutation((event) => updateTicketFn(event));

  const onFinish = (values) => {
    updateTicketMn.mutate(values, {
      onSuccess: async () => {
        message.success("Ticket updated successfully!");
        // Send email once the requirement is clear
        // if (values.ticketStatus === "Completed") {
        //   await api.email
        //     .send(ticket.user.email, ticket.user.name, values.ticketStatus)
        //     .then((data) => {
        //       if (data.data.emailSender.success) {
        //         message.success("Email sent successfully!");
        //       } else {
        //         message.error("Failed to send email!");
        //       }
        //     });
        // }
        queryClient.invalidateQueries("getTicketByRecordId", recordId);
        queryClient.invalidateQueries("getTicketsByCompanyId");
        setVisible(false);
        setImageElements([]);
        setComments([]);
        // setUpdateClicked(false);
        // dispatch(ac.resetTicket());
      },
      onError: () => {
        message.error("Error!");
      },
    });
  };

  let arrayI = [];
  async function getImages(ele) {
    let parsedArray = JSON.parse(ele.imagesUrl);
    if (parsedArray && parsedArray.length > 0 && !imageElements.length) {
      for (let index = 0; index < parsedArray.length; index++) {
        const element = parsedArray[index];
        await Storage.get(element, {
          level: "public",
        })
          .then((data) => arrayI.push({ jobId: ele.id, data: data }))
          .catch((err) => console.log(err));
      }
    }
    if (arrayI && arrayI.length > 0) {
      setImageElements(arrayI);
    }
  }

  let commentImageURLs = useMemo(() => [], []);

  function disabledDate(current) {
    return current && current < moment().startOf("day");
  }

  // function updateTicketStatus(array){
  //   if(array && array.length > 0){
  //     setTicketStatus(true);
  //   }
  // }

  const getCommentImages = useCallback(
    async (id, imageKey) => {
      await Storage.get(imageKey, {
        level: "public",
      })
        .then((data) => commentImageURLs.push({ id: id, url: data }))
        .catch((err) => console.log(err));
    },
    [commentImageURLs],
  );

  // useEffect(() => {
  //   if (ticket) {
  //     setComments(initialCommentsValue);
  //   }
  // }, [initialCommentsValue, ticket]);

  useEffect(() => {
    setComments([]);
    if (ticket) {
      form.setFieldsValue({
        ticketStatus: ticket.ticketStatus,
        // Revert back to line 180 as requested by the client.
        // Uncomment below line (183) if client need that back
        // ticketStatus: moment().isBefore(ticket.expectedDelivery, "day")
        // ? ticket.ticketStatus
        // : "Delayed",
        // notes: ticket.notes,
      });
      let parsedNotes = ticket.notes && JSON.parse(ticket.notes);
      if (parsedNotes && parsedNotes.array && parsedNotes.array.length > 0) {
        parsedNotes.array.forEach((element) => {
          getCommentImages(element.id, element.imageKey);
        });
        setComments(parsedNotes.array);
      }
    }
    if (ticket && ticket.jobs) {
      // dispatch(ac.jobsBatchGet(ticket.jobs));
      queryClient.invalidateQueries("getTicketsByCompanyId");
    }
  }, [
    dispatch,
    ticket,
    form,
    visible,
    commentImageURLs,
    getCommentImages,
    queryClient,
  ]);

  useEffect(() => {
    if (updateJobSuccess) {
      // dispatch(ac.jobsBatchGet(ticket.jobs));
      queryClient.invalidateQueries("getTicketsByCompanyId");
      dispatch(ac.resetJob());
      message.success("Job updated successfully!");
    }
  }, [dispatch, queryClient, ticket, updateJobSuccess]);

  return (
    <>
      {ticket && (
        <Modal
          open={visible}
          onCancel={() => {
            setVisible(false);
            dispatch(ac.resetJob());
            setImageElements([]);
            setComments([]);
            dispatch(ac.resetTicket());
          }}
          footer={null}
        >
          <Spin spinning={ticketFetching || jobsFetching}>
            <div className={classNames.wrapper}>
              <h2>Update Ticket</h2>
              <div>
                {ticket?.jobs &&
                  ticket?.jobs?.length > 0 &&
                  ticket?.jobs?.map((job, index) => {
                    return (
                      <div className={classNames.maintainanceNotice}>
                        {jobs &&
                        jobs.length > 0 &&
                        jobs.filter((j) => j.id === job) ? (
                          <></>
                        ) : (
                          <>
                            <Button
                              type="primary"
                              onClick={(values) => {
                                let input = {
                                  id: job,
                                  ticketId: ticket.id,
                                };
                                dispatch(ac.updateJob(input));
                                queryClient.invalidateQueries(
                                  "getTicketsByCompanyId",
                                );
                              }}
                            >
                              Maintainance Notice (vedlikeholdsvarsel) - Click
                              here to view job {index + 1}
                            </Button>
                          </>
                        )}
                      </div>
                    );
                  })}
              </div>
              <div>
                <div className={classNames.refreshIcon}>
                  <Button
                    type="primary"
                    icon={<MdRefresh size="20px" />}
                    onClick={() =>
                      queryClient.invalidateQueries(
                        "getTicketByRecordId",
                        recordId,
                      )
                    }
                  >
                    Refresh
                  </Button>
                </div>
                <Form
                  form={form}
                  layout="vertical"
                  onFinish={onFinish}
                  onValuesChange={(changedValues, values) => {
                    setChangedValues(values);
                  }}
                  // initialValues={{
                  //   expectedDelivery: moment(ticket.expectedDelivery),
                  //   actualDelivery: moment(ticket.actualDelivery),
                  // }}
                >
                  <p>
                    <span>Id : </span>
                    <span>{ticket.id ? ticket.id.split("+")[1] : ""}</span>
                  </p>
                  <p>
                    <span>Customer Name : </span>
                    <span>{ticket.user?.name}</span>
                  </p>
                  <p>
                    <span>Email : </span>
                    <span>{ticket.user?.email}</span>
                  </p>
                  <p>
                    <span>Mobile Number : </span>
                    <span>{ticket.user?.mobileNumber}</span>
                  </p>
                  <p>
                    <span>Date of Appoinment : </span>
                    <span>
                      {moment(ticket.dateOfReceipt).format("DD/MM/YYYY")}
                    </span>
                  </p>
                  <hr />
                  <h4>Jobs</h4>
                  {jobs && jobs.length > 0
                    ? jobs.map((element, index) => {
                        return (
                          <>
                            <b>
                              Job {index + 1}: {element.jobName}
                            </b>
                            <div
                              key={element.id}
                              className={classNames.formListWrapper}
                            >
                              <Form.Item
                                name={`${element.id}+brandOfTyre`}
                                label="Brand of Tyres"
                                initialValue={element.brandOfTyre}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please select a brand.",
                                  },
                                ]}
                              >
                                <Select>
                                  {data.tyreBrands.map((brand) => (
                                    <Select.Option value={brand} key={brand}>
                                      {brand}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Form.Item>
                              <Form.Item
                                name={`${element.id}+makeOfVehicle`}
                                label="Make of Vehicle"
                                initialValue={element.makeOfVehicle}
                              >
                                <Select>
                                  {data.carbrands.map((brand) => (
                                    <Select.Option value={brand} key={brand}>
                                      {brand}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Form.Item>
                              <Form.Item
                                name={`${element.id}+numberOfRims`}
                                label="Number of Rims"
                                initialValue={element.numberOfRims}
                                rules={[
                                  {
                                    required: true,
                                    message:
                                      "Please specify the number of rims.",
                                  },
                                ]}
                              >
                                <Select
                                  defaultValue={element.numberOfRims}
                                  onChange={(value) => {
                                    form.setFieldsValue({
                                      [`${element.id}+numberOfRims`]: value,
                                    });
                                  }}
                                >
                                  {data.noOfRims.map((num) => (
                                    <Select.Option value={num} key={num}>
                                      {num}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Form.Item>
                              <Form.Item
                                name={`${element.id}+brandOfRims`}
                                label="Brand of Rims"
                                initialValue={element.brandOfRims}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please select a brand of rims.",
                                  },
                                ]}
                              >
                                <Select
                                  defaultValue={element.brandOfRims}
                                  onChange={(value) => {
                                    form.setFieldsValue({
                                      [`${element.id}+brandOfRims`]: value,
                                    });
                                  }}
                                >
                                  {data.rimBrands.map((brand) => (
                                    <Select.Option value={brand} key={brand}>
                                      {brand}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Form.Item>
                              <Form.Item
                                name={`${element.id}+sizeOfRims`}
                                label="Size of Rims"
                                initialValue={element.sizeOfRims}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please select the size of rims.",
                                  },
                                ]}
                              >
                                <Select>
                                  {data.sizeOfRims.map((size) => (
                                    <Select.Option value={size} key={size}>
                                      {size}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Form.Item>
                              {[
                                data.services[2],
                                data.services[3],
                                data.services[5],
                              ].includes(element.jobName) && (
                                <>
                                  {/* Color Field */}
                                  <Form.Item
                                    name={`${element.id}+color`}
                                    label="Color"
                                    initialValue={element.color}
                                    rules={[
                                      {
                                        required: true,
                                        message: "Please select a color.",
                                      },
                                    ]}
                                  >
                                    <Select>
                                      {data.colors.map((color) => (
                                        <Select.Option
                                          value={color}
                                          key={color}
                                        >
                                          {color}
                                        </Select.Option>
                                      ))}
                                    </Select>
                                  </Form.Item>

                                  {/* Paint Type (Matt) */}
                                  <Form.Item
                                    name={`${element.id}+matt`}
                                    label="Paint Type"
                                    initialValue={element.matt}
                                  >
                                    <Select allowClear>
                                      {data.paintTypes.map((type, index) => {
                                        return (
                                          <Select.Option
                                            value={type}
                                            key={index}
                                          >
                                            {type}
                                          </Select.Option>
                                        );
                                      })}
                                    </Select>
                                  </Form.Item>
                                </>
                              )}
                              <Form.Item
                                name={`${element.id}+sizeOfTyre`}
                                label="Size of Tyre"
                                initialValue={element.sizeOfTyre}
                              >
                                <Input placeholder="Enter size of tyre" />
                              </Form.Item>
                              <Form.Item
                                name={`${element.id}+noOfCaps`}
                                label="Number of Caps"
                                initialValue={element.noOfCaps}
                                rules={[
                                  {
                                    required: true,
                                    message:
                                      "Please select the number of caps.",
                                  },
                                ]}
                              >
                                <Select>
                                  {data.noOfCaps.map((caps) => (
                                    <Select.Option value={caps} key={caps}>
                                      {caps}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Form.Item>
                              <Form.Item
                                name={`${element.id}+noOfTPMS`}
                                label="Number of TPMS"
                                initialValue={element.noOfTPMS}
                                rules={[
                                  {
                                    required: true,
                                    message:
                                      "Please select the number of TPMS.",
                                  },
                                ]}
                              >
                                <Select>
                                  {data.noOfTPMS.map((tpms) => (
                                    <Select.Option value={tpms} key={tpms}>
                                      {tpms}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Form.Item>
                              <Form.Item
                                name={`${element.id}+vehicleNumberPlate`}
                                label="Vehicle Number Plate"
                                initialValue={element.vehicleNumberPlate}
                              >
                                <Input placeholder="Enter vehicle number plate" />
                              </Form.Item>
                              <Form.Item
                                name={`${element.id}+notes`}
                                label="Notes"
                                initialValue={element.notes}
                                rules={[
                                  { required: false }, // Set to true if notes are required
                                ]}
                              >
                                <Input.TextArea
                                  placeholder="Enter additional notes"
                                  rows={4}
                                />
                              </Form.Item>
                              <div>
                                {/* {element.imagesUrl ? <div>Images: {element.notes}</div> : null } */}
                                {element.imagesUrl ? (
                                  imageElements.length > 0 &&
                                  imageElements
                                    .filter(
                                      (value, index) =>
                                        element.id === value.jobId,
                                    )
                                    .map((item, index) => {
                                      return (
                                        <Image
                                          id={`job-image-${index}`}
                                          src={item.data}
                                          width={200}
                                          height={200}
                                        />
                                      );
                                    })
                                ) : (
                                  <div>Images Not found</div>
                                )}
                              </div>
                              <Form.Item
                                name={`${element.id}+jobStatus`}
                                label="Job Status"
                                initialValue={element.jobStatus}
                                // Revert back to line 334 as requested by the client.
                                // Uncomment below line (337) if client need that back
                                // initialValue={
                                //   moment().isBefore(
                                //     ticket.expectedDelivery,
                                //     "day"
                                //   )
                                //     ? element.jobStatus
                                //     : "Delayed"
                                // }
                              >
                                <Select>
                                  {data.status.map((num, index) => {
                                    return (
                                      <Select.Option value={num} key={index}>
                                        {num}
                                      </Select.Option>
                                    );
                                  })}
                                </Select>
                              </Form.Item>
                              <Form.Item
                                name={`${element.id}+expectedDelivery`}
                                label="Job Expected Delivery"
                                initialValue={
                                  element.expectedDelivery &&
                                  moment(element.expectedDelivery)
                                }
                              >
                                <DatePicker
                                  disabled={
                                    element.expectedDelivery ? true : false
                                  }
                                  disabledDate={disabledDate}
                                />
                              </Form.Item>
                              <Form.Item
                                name={`${element.id}+actualDelivery`}
                                label="Job Actual Delivery"
                                initialValue={
                                  element.actualDelivery &&
                                  moment(element.actualDelivery)
                                }
                              >
                                <DatePicker disabledDate={disabledDate} />
                              </Form.Item>
                              {/* <Form.Item
                              name={`${element.id}+dateOfReceipt`}
                              label="Date of Received"
                              initialValue={element.dateOfReceipt && moment(element.dateOfReceipt)}
                            >
                              <DatePicker />
                            </Form.Item> */}
                              <Form.Item>
                                <Button
                                  type="primary"
                                  loading={updateJobfetching}
                                  onClick={() => {
                                    const updatedValues = form.getFieldsValue([
                                      `${element.id}+brandOfTyre`,
                                      `${element.id}+numberOfRims`,
                                      `${element.id}+sizeOfRims`,
                                      `${element.id}+brandOfRims`,
                                      `${element.id}+makeOfVehicle`,
                                      `${element.id}+color`,
                                      `${element.id}+matt`,
                                      `${element.id}+sizeOfTyre`,
                                      `${element.id}+noOfCaps`,
                                      `${element.id}+noOfTPMS`,
                                      `${element.id}+vehicleNumberPlate`,
                                      `${element.id}+notes`,
                                      `${element.id}+jobStatus`,
                                      `${element.id}+expectedDelivery`,
                                      `${element.id}+actualDelivery`,
                                      `${element.id}+dateOfReceipt`,
                                    ]);

                                    const input = {
                                      id: element.id,
                                      brandOfTyre:
                                        updatedValues[
                                          `${element.id}+brandOfTyre`
                                        ],
                                      numberOfRims:
                                        updatedValues[
                                          `${element.id}+numberOfRims`
                                        ],
                                      brandOfRims:
                                        updatedValues[
                                          `${element.id}+brandOfRims`
                                        ],
                                      sizeOfRims:
                                        updatedValues[
                                          `${element.id}+sizeOfRims`
                                        ],
                                      makeOfVehicle:
                                        updatedValues[
                                          `${element.id}+makeOfVehicle`
                                        ],
                                      color:
                                        updatedValues[`${element.id}+color`],
                                      matt: updatedValues[`${element.id}+matt`],
                                      sizeOfTyre:
                                        updatedValues[
                                          `${element.id}+sizeOfTyre`
                                        ],
                                      noOfCaps:
                                        updatedValues[`${element.id}+noOfCaps`],
                                      noOfTPMS:
                                        updatedValues[`${element.id}+noOfTPMS`],
                                      vehicleNumberPlate:
                                        updatedValues[
                                          `${element.id}+vehicleNumberPlate`
                                        ],
                                      notes:
                                        updatedValues[`${element.id}+notes`],
                                      jobStatus:
                                        updatedValues[
                                          `${element.id}+jobStatus`
                                        ],
                                      expectedDelivery:
                                        updatedValues[
                                          `${element.id}+expectedDelivery`
                                        ],
                                      actualDelivery:
                                        updatedValues[
                                          `${element.id}+actualDelivery`
                                        ],
                                      dateOfReceipt:
                                        updatedValues[
                                          `${element.id}+dateOfReceipt`
                                        ],
                                    };
                                    dispatch(ac.updateJob(input));
                                  }}
                                >
                                  Update Job
                                </Button>
                              </Form.Item>
                            </div>
                          </>
                        );
                      })
                    : "No jobs available"}

                  <hr />
                  <h4>Ticket Details</h4>
                  <hr />
                  {ticket.expectedDelivery ? (
                    <div>
                      Ticket Expected Delivery :{" "}
                      {moment(ticket.expectedDelivery).format("DD/MM/YYYY")}
                    </div>
                  ) : (
                    <Form.Item
                      name="expectedDelivery"
                      label="Ticket Expected Delivery"
                    >
                      <DatePicker
                        disabled={ticket.expectedDelivery ? true : false}
                        disabledDate={disabledDate}
                      />
                    </Form.Item>
                  )}
                  <Form.Item
                    name="actualDelivery"
                    label={`Ticket Actual Delivery${
                      ticket.actualDelivery &&
                      ` : ${moment(ticket.actualDelivery).format("DD/MM/YYYY")}`
                    }`}
                  >
                    <DatePicker disabledDate={disabledDate} />
                  </Form.Item>
                  <Form.Item name="ticketStatus" label="Status">
                    <Select>
                      {data.status?.map((num, index) => {
                        return (
                          <Select.Option value={num} key={index}>
                            {num}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <Form.Item name="notes" label="Internal Comments">
                    {comments &&
                      comments.length > 0 &&
                      comments.map((value, index) => {
                        let obj =
                          Array.isArray(commentImageURLs) &&
                          commentImageURLs.find((img) => img.id === value.id);
                        return (
                          <div>
                            <p>
                              {value.text && typeof value.text === "string"
                                ? value.text
                                : ""}
                              <div>
                                {commentImageURLs &&
                                  commentImageURLs.length > 0 &&
                                  obj &&
                                  obj.url &&
                                  value?.imageKey?.length > 0 && (
                                    <Image
                                      id={`comment-image-${index}`}
                                      src={obj?.url}
                                      width={100}
                                      height={100}
                                    />
                                  )}
                                <div>
                                  <small>
                                    {moment(value.createdAt).format(
                                      "MMMM Do YYYY, h:mm:ss a",
                                    )}
                                    {value.userName
                                      ? ` by ${value.userName}`
                                      : ""}
                                  </small>
                                </div>
                              </div>
                            </p>
                          </div>
                        );
                      })}
                    <Input onChange={(e) => setChangedValues(e.target.value)} />
                    <Upload
                      // disabled={true}
                      listType="picture"
                      maxCount={1}
                      multiple
                      beforeUpload
                      onChange={(state) =>
                        setCommentImageFile(state.fileList[0]?.originFileObj)
                      }
                      accept=".png, jpeg, .jpg"
                    >
                      <Button icon={<UploadOutlined />}>Upload</Button>
                    </Upload>

                    <Button
                      onClick={async () => {
                        const commentId = uuidv4();
                        const array = [];
                        if (commentImageFile && commentImageFile.type) {
                          await Storage.put(commentId, commentImageFile, {
                            level: "public",
                            contentType: commentImageFile.type,
                          })
                            .then((data) => array.push(data.key))
                            .catch((error) =>
                              console.log("Image Upload Error: ", error),
                            );
                        }
                        comments.push({
                          id: commentId,
                          text: changedValues,
                          imageKey: array[0],
                          createdAt: new Date().toISOString(),
                          userName: currentUser.name,
                        });
                        let input = {
                          id: recordId,
                          notes: JSON.stringify(commentsObj),
                        };
                        // dispatch(ac.updateTicket(input));
                        updateTicketMn.mutate(input, {
                          onSuccess: () => {
                            message.success("Comment added successfully!");
                            setCommentImageFile(null);
                            form.resetFields(["notes"]);
                            setChangedValues(null);
                            queryClient.invalidateQueries(
                              "getTicketByRecordId",
                              recordId,
                            );
                            queryClient.invalidateQueries(
                              "getTicketsByCompanyId",
                            );
                            // dispatch(ac.listTicketsByCompanyId());
                          },
                          onError: () => {
                            message.error("Error!");
                          },
                        });
                      }}
                      loading={updateJobfetching}
                    >
                      Add Comment
                    </Button>
                  </Form.Item>
                  <Form.Item>
                    <Button loading={fetching} type="primary" htmlType="submit">
                      {" "}
                      Update{" "}
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </Spin>
        </Modal>
      )}
    </>
    // )
  );
}
