import { Button, Form, Input, message, Modal, Select } from "antd";
import classNames from "./updateAdminUserModal.module.scss";
import { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "../../../../api";

export default function CreateMetaDataModal({
  visible,
  setVisible,
  onFinish, // Optional: To refresh data after creation
  metaDataTypes,
}) {
  const [form] = Form.useForm();
  const [selectedType, setSelectedType] = useState(null);
  const [subFields, setSubFields] = useState([]);
  const queryClient = useQueryClient();

  const createMetaDataMutationFn = async (event) => api.metaData.create(event);
  const createMetaDataMutation = useMutation((event) =>
    createMetaDataMutationFn(event),
  );
  useEffect(() => {
    if (selectedType) {
      const metaType = metaDataTypes.find((type) => type.id === selectedType);
      setSubFields(metaType?.subFields || []);
    } else {
      setSubFields([]);
    }
  }, [selectedType, metaDataTypes]);

  const handleSubmit = async (values) => {
    // Prepare the additionalInfo object
    const additionalInfo = {};
    subFields.forEach((field) => {
      if (values[field.id]) {
        additionalInfo[field.id] = values[field.id];
      }
    });

    const metaData = {
      input: {
        type: selectedType,
        name: values.name,
        additionalInfo: JSON.stringify(additionalInfo),
      },
    };

    createMetaDataMutation.mutate(metaData, {
      onSuccess: () => {
        setVisible(false);
        queryClient.invalidateQueries("getmetadataByCompanyId");
        form.resetFields();
        onFinish && onFinish();
        message.success("Meta data created successfully.");
      },
      onError: () => {
        message.error("Error creating meta data!");
      },
    });
  };

  return (
    <Modal
      open={visible}
      onCancel={() => {
        setVisible(false);
        form.resetFields();
      }}
      footer={null}
    >
      <div className={classNames.wrapper}>
        <h2>Create Meta Data</h2>
      </div>
      <Form form={form} onFinish={handleSubmit}>
        <Form.Item
          name="type"
          label="Type"
          rules={[{ required: true, message: "Type is required." }]}
        >
          <Select
            placeholder="Select Type"
            onChange={(value) => setSelectedType(value)}
          >
            {metaDataTypes.map((type) => (
              <Select.Option key={type.id} value={type.id}>
                {type.fieldName}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="name"
          label="Name"
          rules={[
            { required: true, message: "Name is required." },
            {
              validator: (_, value) => {
                if (!value.trim()) {
                  return Promise.reject(new Error("Name cannot be empty."));
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input placeholder="Enter name" />
        </Form.Item>

        {subFields.map((field) => (
          <Form.Item
            key={field.id}
            name={field.id}
            label={field.fieldName}
            rules={[
              { required: true, message: `${field.fieldName} is required.` },
              {
                validator: (_, value) => {
                  if (field.id === "servicesWithDueDates" && isNaN(value)) {
                    return Promise.reject(new Error("Must be a number."));
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input placeholder={`Enter ${field.fieldName}`} />
          </Form.Item>
        ))}

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Create
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}
