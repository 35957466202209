import { API, graphqlOperation } from "aws-amplify";
import { managerResetPassword } from "../../graphql/mutations";

export default async function managerResetPasswordApi(input) {
  const variables = {
    ...input,
  };
  return await API.graphql(
    graphqlOperation(managerResetPassword, { ...variables }),
  );
}
