import { applyMiddleware, combineReducers, createStore } from "redux";
import thunk from "redux-thunk";
import {
  signInReducer,
  createTicketReducer,
  signOutReducer,
  listTicketsByCompanyIdReducer,
  createJobReducer,
  createUserReducer,
  getUserReducer,
  getUsersByCompanyIdReducer,
  getTicketByIdReducer,
  jobsBatchGetReducer,
  updateTicketReducer,
  deleteTicketReducer,
  updateJobReducer,
  completeNewPasswordReducer,
  deleteUserReducer,
  listAdminUsersReducer,
  inviteMemberReducer,
  fetchCurrentUserReducer,
  forgotPasswordReducer,
  forgotPasswordSubmitReducer,
  updateUserReducer,
  getUserByEmailReducer,
} from "./reducers";

export const store = createStore(
  combineReducers({
    signIn: signInReducer,
    signOut: signOutReducer,
    createJob: createJobReducer,
    createTicket: createTicketReducer,
    listTicketsByCompanyId: listTicketsByCompanyIdReducer,
    createUser: createUserReducer,
    getUser: getUserReducer,
    getUsersByCompanyId: getUsersByCompanyIdReducer,
    getTicketById: getTicketByIdReducer,
    jobsBatchGet: jobsBatchGetReducer,
    updateTicket: updateTicketReducer,
    deleteTicket: deleteTicketReducer,
    updateJob: updateJobReducer,
    completeNewPassword: completeNewPasswordReducer,
    deleteUser: deleteUserReducer,
    inviteMember: inviteMemberReducer,
    listAdminUsers: listAdminUsersReducer,
    fetchCurrentUser: fetchCurrentUserReducer,
    forgotPassword: forgotPasswordReducer,
    forgotPasswordSubmit: forgotPasswordSubmitReducer,
    updateUser: updateUserReducer,
    getUserByEmail: getUserByEmailReducer,
  }),
  applyMiddleware(thunk),
);
