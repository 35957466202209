import api from "../../../api";
import { types } from "../../types";

export default function getTicketByIdAction(id) {
  return async function getTicketByIdActionThunk(dispatch) {
    dispatch({ type: types.ticket.getTicketById.started });

    try {
      const data = await api.ticket.get(id);
      dispatch({ type: types.ticket.getTicketById.success, payload: data });
    } catch (error) {
      dispatch({ type: types.ticket.getTicketById.failed });
    }
  };
}
