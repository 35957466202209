import { useState } from "react";
import { Link } from "react-router-dom";
import { Button, Modal } from "antd";
import SignOut from "../Admin/Auth/SignOut";
import classNames from "./Header.module.scss";
import SignIn from "../Admin/Auth/SignIn";
import { useSelector } from "react-redux";
// import { MdDashboard } from "react-icons/md";
// import { useLocation } from "react-router";

export default function Header({ signedIn }) {
  const [visible, setVisible] = useState(true);
  // const loc = useLocation();

  const adminUserList = useSelector(function ({ listAdminUsers }) {
    return signedIn && listAdminUsers.data
      ? listAdminUsers.data
        ? listAdminUsers.data.listAdminUsers
        : []
      : [];
  });

  const fetchCurrentUserData = useSelector(function ({ fetchCurrentUser }) {
    return fetchCurrentUser ? fetchCurrentUser.data : {};
  });

  const signInUserData = useSelector(function ({ signIn }) {
    return signIn ? signIn.data : {};
  });

  const userObj = signInUserData || fetchCurrentUserData;

  console.log("adminUserList", adminUserList);
  const currentUserObj =
    signedIn &&
    userObj &&
    adminUserList.items &&
    adminUserList.items.length > 0 &&
    adminUserList.items.find((value, index) => value.id === userObj.username);

  return (
    <div className={classNames.wrapper}>
      {/* {signedIn && loc.pathname === "/dashboard" && (
        <Link to="/create-ticket">
          <Button type="primary">Create Ticket</Button>
        </Link>
      )} */}
      <div className={classNames.adminPanel}>
        {!signedIn ? (
          <div>
            {/* // <Link to="/admin"> */}
            <Modal
              visible={visible}
              closable={false}
              // onCancel={() => {
              //   setVisible(false);
              // }}
              footer={null}
            >
              <SignIn setVisible={setVisible} />
            </Modal>
            <Button type="primary" onClick={() => setVisible(true)}>
              Sign In
            </Button>
            {/* // </Link> */}
          </div>
        ) : (
          <div>
            <span className={classNames.displayName}>
              Hi{" "}
              {currentUserObj?.name && currentUserObj?.name.length > 10
                ? currentUserObj?.name.split(" ")[0]
                : currentUserObj?.name}
            </span>
            <Link to="/dashboard">
              {/* <MdDashboard size="30px" /> */}
              <Button type="primary">Dashboard</Button>
            </Link>
          </div>
        )}
        {signedIn && <SignOut />}
      </div>
    </div>
  );
}
