/* eslint-disable default-case */
import { types } from "../../types";

const initialState = {
  fetching: false,
  success: false,
  data: false,
  error: false,
};

export default function fetchCurrentUserReducer(state = initialState, action) {
  switch (action.type) {
    case types.auth.fetchCurrentUser.started:
      return { fetching: true, success: false, data: false };
    case types.auth.fetchCurrentUser.success:
      return { fetching: false, success: true, data: action.payload };
    case types.auth.fetchCurrentUser.failed:
      return {
        fetching: false,
        success: false,
        data: action.payload,
        error: true,
      };
    case types.auth.fetchCurrentUser.reset:
      return { fetching: false, success: false, data: false, error: false };
  }
  return state;
}
