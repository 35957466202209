function generateTypes(namespace) {
  return {
    started: `${namespace}.started`,
    success: `${namespace}.success`,
    failed: `${namespace}.failed`,
    reset: `${namespace}.reset`,
  };
}

export const types = {
  auth: {
    signIn: generateTypes("signIn"),
    signOut: generateTypes("signOut"),
    completeNewPassword: generateTypes("completeNewPassword"),
    inviteMember: generateTypes("inviteMember"),
    listAdminUsers: generateTypes("listAdminUsers"),
    fetchCurrentUser: generateTypes("fetchCurrentUser"),
    forgotPassword: generateTypes("forgotPassword"),
    forgotPasswordSubmit: generateTypes("forgotPasswordSubmit"),
  },
  job: {
    create: generateTypes("job.create"),
    batchGet: generateTypes("job.batchGet"),
    delete: generateTypes("job.delete"),
    update: generateTypes("job.update"),
  },
  ticket: {
    create: generateTypes("ticket.create"),
    listTicketsByCompanyId: generateTypes("ticket.listTicketsByCompanyId"),
    getTicketById: generateTypes("ticket.getTicketById"),
    update: generateTypes("ticket.update"),
    delete: generateTypes("ticket.delete"),
  },
  user: {
    create: generateTypes("user.create"),
    get: generateTypes("user.get"),
    getUsersByComapnyId: generateTypes("usersByCompanyId.get"),
    delete: generateTypes("user.delete"),
    update: generateTypes("user.update"),
    byEmail: generateTypes("user.byEmail"),
  },
  reset: {
    job: generateTypes("job.reset"),
    ticket: generateTypes("ticket.reset"),
    user: generateTypes("user.reset"),
  },
};
