import signIn from "./Auth/signIn.api";
import signOut from "./Auth/signOut.api";
import createTicketApi from "./Ticket/createTicket.api";
import listTicketsApi from "./Ticket/listTicketsByCompanyId.api";
import createJobApi from "./Job/createJob.api";
import createUserApi from "./User/createUser.api";
import getUserApi from "./User/getUser.api";
import getUsersByCompanyIdApi from "./User/getUsersByCompanyId.api.";
import getTicketByIdApi from "./Ticket/getTicketById.api";
import jobsBatchGetApi from "./Job/jobsBatchGet.api";
import updateTicketApi from "./Ticket/updateTicket.api";
import deleteTicketByIdApi from "./Ticket/deleteTicket.api";
import deleteJobApi from "./Job/deleteJob.api";
import updateJobApi from "./Job/updateJob.api";
import completeNewPassword from "./Auth/completeNewPassword.api";
import deleteUserByIdApi from "./User/deleteUser.api";
import inviteMemberApi from "./Auth/inviteMember.api";
import listAdminUsersApi from "./Auth/listAdminUsers.api";
import fetchCurrentUser from "./Auth/fetchCurretUser.api";
import forgotPassword from "./Auth/forgotPassword.api";
import forgotPasswordSubmit from "./Auth/forgotPasswordSubmit.api";
import updateUserApi from "./User/updateUser.api";
import getUserByEmailApi from "./User/getUserByEmail.api";
import sendEmailApi from "./Email/send";
import updateAdminUserApi from "./Auth/updateAdminUser.api";
import deleteManagerUserApi from "./Auth/deleteManagerUser.api";
import managerResetPasswordApi from "./Auth/managerResetPassword.api";
import listMetadataApi from "./MetaData/list";
import createMetaDataApi from "./MetaData/create";
import updateMetaDataApi from "./MetaData/update";
import deleteMeatDataApi from "./MetaData/delete";
const api = {
  auth: {
    signIn: signIn,
    signOut: signOut,
    completeNewPassword: completeNewPassword,
    inviteMember: inviteMemberApi,
    listAdminUsers: listAdminUsersApi,
    fetchCurrentUser: fetchCurrentUser,
    forgotPassword: forgotPassword,
    forgotPasswordSubmit: forgotPasswordSubmit,
    updateAdminUserApi: updateAdminUserApi,
    deleteManagerUserApi: deleteManagerUserApi,
    managerResetPassword: managerResetPasswordApi,
  },
  job: {
    create: createJobApi,
    batchGet: jobsBatchGetApi,
    delete: deleteJobApi,
    update: updateJobApi,
  },
  ticket: {
    create: createTicketApi,
    list: listTicketsApi,
    get: getTicketByIdApi,
    update: updateTicketApi,
    delete: deleteTicketByIdApi,
  },
  user: {
    create: createUserApi,
    get: getUserApi,
    getUsersByCompany: getUsersByCompanyIdApi,
    delete: deleteUserByIdApi,
    update: updateUserApi,
    byEmail: getUserByEmailApi,
  },
  email: {
    send: sendEmailApi,
  },
  metaData: {
    list: listMetadataApi,
    create: createMetaDataApi,
    update: updateMetaDataApi,
    delete: deleteMeatDataApi,
  },
};

export default api;
