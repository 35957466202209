import api from "../../../api";
import { types } from "../../types";

export default function completeNewPassword(userObj, values) {
  return async function completeNewPasswordThunk(dispatch) {
    dispatch({ type: types.auth.completeNewPassword.started, payload: false });
    try {
      const data = await api.auth.completeNewPassword(userObj, values);
      dispatch({ type: types.auth.completeNewPassword.success, payload: data });
    } catch (error) {
      dispatch({ type: types.auth.completeNewPassword.failed, payload: error });
    }
  };
}
