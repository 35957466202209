import signInAction from "./Auth/signIn.ac";
import signOutAction from "./Auth/signOut.ac";
import createTicketAction from "./Ticket/createTicket.ac";
import listTicketsByCompanyIdAction from "./Ticket/listTicketsByCompanyId.ac";
import createJobAction from "./Job/createJob.ac";
import createUserAction from "./User/createUser.ac";
import getUserAction from "./User/getUser.ac";
import getUsersByCompanyId from "./User/getUsersByCompanyd.ac";
import getTicketByIdAction from "./Ticket/getTicketById.ac";
import jobsBatchGetAction from "./Job/jobsBatchGet.ac";
import updateTicketAction from "./Ticket/updateTicket.ac";
import deleteTicketAction from "./Ticket/deleteTicket.ac";
import deleteJobAction from "./Job/deleteJob.ac";
import updateJobAction from "./Job/updateJob.ac";
import resetJobAction from "./Job/resetJob.ac";
import completeNewPassword from "./Auth/completeNewPassword.ac";
import deleteUserAction from "./User/deleteUser.ac";
import resetTicketAction from "./Ticket/resetTicket.ac";
import inviteMemberAction from "./Auth/inviteMember.ac";
import listAdminUsersIdAction from "./Auth/listAdminUsers.ac";
import fetchCurrentUserAction from "./Auth/fetchCurrentUser.ac";
import forgotPasswordAction from "./Auth/forgotPassword.ac";
import forgotPasswordSubmitAction from "./Auth/forgotPasswordSubmit.ac";
import updateUserAction from "./User/updateUser.ac";
import resetUserAction from "./User/resetUser.ac";
import getUserByEmailAction from "./User/getUserByEmail.ac";
export const ac = {
  signIn: signInAction,
  signOut: signOutAction,
  createJob: createJobAction,
  createTicket: createTicketAction,
  listTicketsByCompanyId: listTicketsByCompanyIdAction,
  createUser: createUserAction,
  getUser: getUserAction,
  getUsersByCompanyId: getUsersByCompanyId,
  updateUser: updateUserAction,
  getTicketById: getTicketByIdAction,
  jobsBatchGet: jobsBatchGetAction,
  updateTicket: updateTicketAction,
  deleteTicket: deleteTicketAction,
  deleteJob: deleteJobAction,
  updateJob: updateJobAction,
  resetJob: resetJobAction,
  completeNewPassword: completeNewPassword,
  deleteUser: deleteUserAction,
  resetTicket: resetTicketAction,
  resetUser: resetUserAction,
  inviteMember: inviteMemberAction,
  listAdminUsers: listAdminUsersIdAction,
  fetchCurrentUser: fetchCurrentUserAction,
  forgotPassword: forgotPasswordAction,
  forgotPasswordSubmit: forgotPasswordSubmitAction,
  getUserByEmail: getUserByEmailAction,
};
